import React from 'react';
import { Flex } from 'antd';

import { text } from './lib/text';
import P from '../../../../shared/ui/P';
import { ParagraphSize } from '../../../../shared/types/typography.interface';
import Unit from '../../../../shared/ui/Unit';
import { DocumentSubParagraph } from '../../ui/Document/ui/DocumentSubParagraph';

export const Terms = () => {
    return (
        <Flex vertical gap={12}>
            <P size={ParagraphSize.h2} text={'Terms of Use '} />

            {text.map((p, i) => (
                <Unit key={i}>
                    <P
                        size={ParagraphSize.h3}
                        text={`${p.number} ` + `${p.title}`}
                    />
                    <P size={ParagraphSize.secondaryBody} text={p.text} />
                    {p.subParagraphs && (
                        <DocumentSubParagraph subParagraphs={p.subParagraphs} />
                    )}
                </Unit>
            ))}
        </Flex>
    );
};
