import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Flex, Skeleton } from 'antd';

import Actions from 'shared/ui/Actions';
import MainButton from 'shared/ui/MainButton';
import Placeholder from 'shared/ui/Placeholder';
import Unit from 'shared/ui/Unit';
import { DeliveryInfo } from 'wigets/order/delivery-info/deliveryInfo';
import ProductsList from 'wigets/order/products-list/productsList';
import Wrapper from 'shared/ui/Wrapper';
import ErrorHeader from 'features/Checkout/ui/ErrorHeader';
import { Screen } from 'shared/ui/Screen/ui/Screen';

import { useReadOrderQuery } from '../../../api/order.api';
import { useCreateRepeatedBuyMutation } from '../../../api/checkout.api';
import Loader from '../../../features/Checkout/ui/Loader';
import useSessionStorage from '../../../shared/hooks/useSessionStorage';

export const CheckoutErrorPage = () => {
    const { id } = useParams();
    const { data: order, isLoading } = useReadOrderQuery(+id);
    const navigate = useNavigate();
    const [createRepeatedBuy, { data, isSuccess, isLoading: isLoadingPay }] =
        useCreateRepeatedBuyMutation();

    const [paymentUrl, setPaymentUrl] = useSessionStorage('paymentUrl', '');
    const [subtotal, setSubtotal] = useSessionStorage('subtotal', '');
    const [deliveryPrice, setDeliveryPrice] = useSessionStorage(
        'deliveryPrice',
        '',
    );

    const [open, setOpen] = useState<boolean>(false);

    const handleSuccess = (response: any) => {
        setPaymentUrl(response.redirect);
        setSubtotal(order?.total?.toString());
        setDeliveryPrice(order?.delivery?.delivery_sum);
        setOpen(false);

        navigate('/checkout/confirm');
    };

    const handleBuy = useCallback(async () => {
        setOpen(true);

        const payload = {
            telegram_user_id: order?.telegram_user_id,
            order_id: order?.order_id,
        };

        await createRepeatedBuy(payload).then(
            r => r?.data?.redirect?.length > 2 && handleSuccess(r?.data),
        );
    }, [order]);

    const buttonText = 'Try again';

    return (
        <Screen>
            {open && <Loader />}

            <Wrapper
                style={open ? { opacity: '0.7', filter: 'blur(7px)' } : {}}
                position={'first'}
            >
                <Flex vertical gap={24}>
                    <ErrorHeader isLoading={isLoading} id={order?.order_id} />

                    {isLoading ? (
                        <Unit>
                            <Flex vertical={false} align={'center'} gap={16}>
                                <Skeleton.Image active />
                                <Skeleton paragraph={{ rows: 1 }} active />
                            </Flex>
                        </Unit>
                    ) : (
                        <ProductsList items={order?.product_list} />
                    )}

                    {isLoading ? (
                        <Unit>
                            <Skeleton paragraph={{ rows: 1 }} active />
                        </Unit>
                    ) : (
                        <DeliveryInfo {...order?.delivery} />
                    )}
                </Flex>
            </Wrapper>
            <Placeholder />
            <Actions
                style={open ? { opacity: '0.7', filter: 'blur(7px)' } : {}}
            >
                <MainButton disabled={open} onClick={handleBuy}>
                    {buttonText}
                </MainButton>
            </Actions>
        </Screen>
    );
};
