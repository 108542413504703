import React, { memo, useContext } from 'react';
import { Divider, Flex, Spin } from 'antd';
import dayjs from 'dayjs';

import Page from 'shared/ui/Page';
import P from 'shared/ui/P';
import { ParagraphSize } from 'shared/types/typography.interface';
import Unit from 'shared/ui/Unit';
import { OrderResponse } from 'shared/types/order.interface';

import { PaymentInfo } from '../../payment/PaymentInfo/PaymentInfo';

export const SummaryInfo = memo((props: OrderResponse) => {
    const { order_id, created_at, product_list, status_name, total, delivery } =
        props;

    return (
        <Page>
            <P size={ParagraphSize.h2} text={'Summary'} />
            <Unit gap={8}>
                <Flex justify={'space-between'} align={'center'}>
                    <P
                        type={'secondary'}
                        size={ParagraphSize.body}
                        text={'Order number'}
                    />

                    <P size={ParagraphSize.body} text={`${order_id}`} />
                </Flex>

                <Flex justify={'space-between'} align={'center'}>
                    <P
                        type={'secondary'}
                        size={ParagraphSize.body}
                        text={'Date'}
                    />

                    <P
                        size={ParagraphSize.body}
                        text={dayjs(created_at).format('MMM D, YYYY')}
                    />
                </Flex>

                <Flex justify={'space-between'} align={'center'}>
                    <P
                        type={'secondary'}
                        size={ParagraphSize.body}
                        text={'Items'}
                    />

                    <P
                        size={ParagraphSize.body}
                        text={`${product_list?.length}`}
                    />
                </Flex>

                <Flex justify={'space-between'} align={'center'}>
                    <P
                        type={'secondary'}
                        size={ParagraphSize.body}
                        text={'Status'}
                    />

                    <P
                        weight={700}
                        size={ParagraphSize.body}
                        text={status_name}
                    />
                </Flex>

                <Divider style={{ margin: '16px 0' }} />

                <PaymentInfo
                    subtotal={total}
                    delivery={+delivery?.delivery_sum}
                />
            </Unit>
        </Page>
    );
});
