import React, { PropsWithChildren } from 'react';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';

import { store } from 'shared/store';
import defaultThemeConfig from 'shared/config/theme/defaultTheme';

import { CurrencyProvider } from './lib/CurrencyContext';
import { LayoutProvider } from './lib/LayoutContext';
import { DiscountProvider } from './lib/DiscountContext';

export const ExtraProviders = ({ children }: PropsWithChildren) => {
    const initialJSX = <>{children}</>;

    const providers = [
        { Component: Provider, props: { store: store } },
        { Component: ConfigProvider, props: { theme: defaultThemeConfig } },
        { Component: CurrencyProvider },
        { Component: LayoutProvider },
        { Component: DiscountProvider },
    ];

    return providers.reduceRight(
        (prevJSX, { Component: CurrentProvider, props = {} }) => {
            return <CurrentProvider {...props}>{prevJSX}</CurrentProvider>;
        },
        initialJSX,
    );
};
