import React, { memo, useContext, useEffect, useState } from 'react';
import { Flex, Image, Skeleton } from 'antd';

import notoriginal from 'shared/static/images/notoriginal-catalog.png';
import notagame from 'shared/static/images/notagame-catalog.svg';
import { gap } from 'shared/config/theme/constants';

import useSessionStorage from '../../../../../shared/hooks/useSessionStorage';
import { LayoutContext } from '../../../../Utils/lib/Providers/lib/LayoutContext';

interface HeaderTypes {
    readonly activeId: string;
}
export const Header = memo((props: HeaderTypes) => {
    const { activeId } = props;
    const { wrapperWidth } = useContext(LayoutContext);
    const [src, setSrc] = useState(notagame);

    const [mainCategoryId, setMainCategoryId] = useSessionStorage(
        'mainCategoryId',
        '',
    );

    const setImageSrc = () => {
        if (mainCategoryId == activeId) {
            return notagame;
        } else return notoriginal;
    };

    useEffect(() => {
        if (+mainCategoryId === +activeId) {
            setSrc(notagame);
        } else setSrc(notoriginal);
    }, [mainCategoryId, activeId]);

    return (
        <Flex
            style={{ marginBottom: gap * 2 }}
            align={'center'}
            justify={'center'}
        >
            {!activeId ? (
                <Skeleton.Image
                    style={{
                        minWidth: wrapperWidth,
                        width: wrapperWidth,
                        maxWidth: wrapperWidth,
                        minHeight: 170,
                    }}
                    active={!activeId}
                />
            ) : (
                <Image
                    loading={'lazy'}
                    style={{
                        width: wrapperWidth,
                    }}
                    preview={false}
                    src={src}
                />
            )}
        </Flex>
    );
});
