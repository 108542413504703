import React, { lazy } from 'react';
import { Flex, Form, FormInstance, Input } from 'antd';

import CountrySelector from 'features/Checkout/ui/CountrySelector';

import { CheckoutFields } from '../../../types/checkout.interface';
import { FormFields } from '../../../types/utils.interface';

interface FormTypes {
    readonly name: string;
    readonly disabled?: boolean;
    readonly fields: FormFields[];
    readonly form: FormInstance<CheckoutFields>;
    readonly libType: string;
}

const G = lazy(() => import('./GoogleInput'));
const D = lazy(() => import('./DadataField'));

export const FormHandler = (props: FormTypes) => {
    const { name, form, disabled, fields, libType } = props;

    return (
        <Form
            form={form}
            scrollToFirstError
            name={name + '_form'}
            labelAlign='left'
            layout={'vertical'}
            disabled={disabled}
            requiredMark={false}
        >
            <Flex vertical gap={16}>
                {fields.map(field => (
                    <Form.Item
                        style={{ fontWeight: 600 }}
                        key={field.name}
                        label={field.label}
                        name={field.name}
                        rules={field.rules}
                        hidden={field?.hidden}
                    >
                        {field?.type === 'select' ? (
                            <CountrySelector form={form} />
                        ) : libType === 'global' &&
                          field.name === 'address1' ? (
                            <G form={form} field={field} />
                        ) : libType === 'ru' && field.name === 'address1' ? (
                            <D form={form} field={field} />
                        ) : (
                            <Input
                                style={{
                                    background: '#1C1C1E',
                                }}
                                variant='outlined'
                                {...field.input}
                            />
                        )}
                    </Form.Item>
                ))}
            </Flex>
        </Form>
    );
};
