import { CSSProperties, ReactNode } from 'react';
import { BaseType } from 'antd/es/typography/Base';

export interface Paragraph {
    readonly size: ParagraphSize;
    readonly text: string;
    readonly weight?: number;
    readonly type?: BaseType;
    readonly style?: CSSProperties;
    readonly extra?: ReactNode;
    readonly isLink?: boolean;
}

export enum ParagraphSize {
    h1 = 'h1',
    h2 = 'h2',
    h3 = 'h3',
    body = 'body',
    secondaryBody = 'secondaryBody',
    caption = 'caption',
}
