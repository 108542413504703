import { createGlobalStyle } from 'styled-components';

import { whiteColor } from './constants';

const DadataStyles = createGlobalStyle`

    .pac-container {
        background-color: rgb(28, 28, 30);
        border-radius: 10px;
        border-top: 0;
    }

    .pac-item {
        cursor: pointer;
        font-size: 15px;
        color: #ffffff;
        padding: 8px;
        border-top: 0;
    }

    .pac-item:hover {
        background-color: #545458A6;
    }

    .pac-item-query {
        font-size: 15px;
        color: #ffffff
    }

    .pac-matched {
        font-size: 15px;
        color: white
    }

    .hdpi .pac-icon {
        display: none;
    }

    .react-dadata__container {
        position: relative;
    }
    .react-dadata__input {
        box-sizing: border-box;
        display: block;
        outline: none;
        width: 100%;

        margin: 0;
        padding: 8px 16px;
        font-size: 17px;
        line-height: 1.5714285714285714;
        list-style: none;
        width: 100%;
        text-align: start;
        border-radius: 10px;
        outline: 0;
        //transition: all 0.2s linear;
        appearance: textfield;
        vertical-align: top;
        background: rgb(28, 28, 30);
        color: #FFFFFF;
        border: none

    }
    .react-dadata__input:focus {
        border: 1px solid #FFFFFF;
        border-width: 1px;
        border-radius: 12px;
    }
    
    .react-dadata__suggestions {
        background-color: #000;
        border-radius: 4px;
        box-shadow: 0 1px 6px 3px rgba(0, 0, 0, 0.1);
        left: 0;
        list-style: none;
        margin: 0;
        overflow: hidden;
        padding: 0;
        position: absolute;
        right: 0;
        text-align: left;
        top: calc(100% + 8px);
        z-index: 10;
    }
    .react-dadata__suggestion-note {
        color: #828282;

        font-size: 14px;
        padding: 10px 10px 5px;
    }
    .react-dadata__suggestion {
        color: #FFF;

        height: 40px;
        background: none;
        border: none;
        box-sizing: border-box;
        cursor: pointer;
        display: block;
        padding: 7px 10px;
        text-align: left;
        width: 100%;
        background: #1C1C1E;
    }
    .react-dadata__suggestion--line-through {
        text-decoration: line-through;
    }
    .react-dadata__suggestion-subtitle {
        color: #777;
        margin-top: 4px;
    }
    .react-dadata__suggestion-subtitle-item {
        display: inline-block;
        margin-bottom: 4px;
        margin-right: 16px;
    }
    .react-dadata__suggestion-subtitle-item:last-child {
        margin-right: 0;
    }
    .react-dadata__suggestion--current {
        background-color: rgba(0, 124, 214, 0.15);
    }
    .react-dadata__suggestion:hover {
        background-color: rgba(0, 124, 214, 0.1);
    }
    .react-dadata mark {
        background: none;
    }
    .react-dadata--highlighted {
        color: ${whiteColor};
        font-weight: 700;
    }
`;

export default DadataStyles;
