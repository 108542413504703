import { useEffect, useState } from 'react';

import useSessionStorage from './useSessionStorage';
import { CartUpdatePayload } from '../types/cart.interface';

export const useCart = (productId?: number) => {
    const [cart, setCart] = useSessionStorage('cart', null);
    const [inCart, setInCart] = useState(false);
    const [count, setCount] = useState(0);

    const addToCart = (props: { payload: CartUpdatePayload }) => {
        const { payload } = props;

        if (cart) {
            return setCart([...cart, payload]);
        }
        setCart([payload]);
    };

    const removeFromCart = (id: number) => {
        setCart(cart?.filter(elem => elem.product_id !== id));
    };

    useEffect(() => {
        if (cart) {
            setInCart(cart?.some(item => item?.product_id === +productId));
            setCount(cart?.length);
        }
    }, [cart]);

    return { cart, addToCart, removeFromCart, inCart, setInCart, count };
};
