import React from 'react';
import { Image } from 'antd';

import footerImage from 'shared/static/images/notcoin-mozhno.svg';

export const Notcoin = () => {
    return (
        <Image style={{ maxWidth: '85%' }} preview={false} src={footerImage} />
    );
};
