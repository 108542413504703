export const roundedDiscountPrice = (
    discount: number,
    price: number,
    isClear?: boolean,
) => {
    const locale = 'en-US';

    if (isClear && discount > 0) {
        const _tempPrice = (price * (100 - discount)) / 100;
        return _tempPrice.toLocaleString(locale);
    }

    if (discount > 0) {
        return Math.ceil((price * (100 - discount)) / 100).toLocaleString(
            locale,
        );
    } else {
        return (Math.ceil(price * 100) / 100).toLocaleString(locale);
    }
};
