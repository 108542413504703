import React, { memo } from 'react';
import { Flex } from 'antd';
import dayjs from 'dayjs';

import P from 'shared/ui/P';
import { ParagraphSize } from 'shared/types/typography.interface';
import Arrow from 'shared/ui/Icons/Arrow';
import { OrderResponse } from 'shared/types/order.interface';

export const OrderInList = memo((props: OrderResponse) => {
    const { order_id, status_name, created_at, product_list } = props;

    return (
        <Flex vertical gap={16}>
            <Flex vertical={false} justify={'space-between'} align={'center'}>
                <Flex vertical>
                    <P
                        weight={590}
                        size={ParagraphSize.h3}
                        text={`Order: ${order_id}`}
                    />
                    <P
                        weight={400}
                        type={'secondary'}
                        size={ParagraphSize.body}
                        text={`Date: ${dayjs(created_at).format('MMM D, YYYY')}`}
                    />
                </Flex>
                <Flex
                    vertical={false}
                    align={'center'}
                    justify={'center'}
                    style={{ marginLeft: 'auto' }}
                    gap={8}
                >
                    <P
                        type={'secondary'}
                        size={ParagraphSize.h3}
                        text={`${product_list?.length} items`}
                    />
                    <Arrow position={'right'} />
                </Flex>
            </Flex>

            <Flex vertical={false} gap={4}>
                <P
                    weight={400}
                    type={'secondary'}
                    size={ParagraphSize.body}
                    text={'Status:'}
                />
                <P weight={700} size={ParagraphSize.body} text={status_name} />
            </Flex>
        </Flex>
    );
});
