import env from '@beam-australia/react-env';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { OrderResponse } from '../shared/types/order.interface';

export const checkoutApi = createApi({
    reducerPath: 'checkoutApi',
    baseQuery: fetchBaseQuery({
        baseUrl: env('API_URL'),
    }),
    tagTypes: ['Checkout'],
    endpoints: builder => ({
        readStatus: builder.query<OrderResponse, number>({
            query: id => {
                return {
                    url: `/market/show/order/${id}`,
                    method: 'GET',
                };
            },
            transformResponse: (res: { data: OrderResponse }) => {
                return res.data;
            },
            providesTags: ['Checkout'],
        }),

        createBuy: builder.mutation({
            query: body => ({
                url: `/market/product/buy`,
                method: 'POST',
                body,
            }),
            transformResponse: (response: {
                data: { order_id?: number; redirect?: string };
            }) => {
                return response.data;
            },
            invalidatesTags: ['Checkout'],
        }),
        createRepeatedBuy: builder.mutation({
            query: body => ({
                url: `/market/product/pay_order`,
                method: 'POST',
                body,
            }),
            transformResponse: (response: { data: { redirect: string } }) => {
                return response.data;
            },
            invalidatesTags: ['Checkout'],
        }),
    }),
});

export const {
    useLazyReadStatusQuery,
    useCreateBuyMutation,
    useCreateRepeatedBuyMutation,
} = checkoutApi;
