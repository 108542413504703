import env from '@beam-australia/react-env';
import queryString from 'query-string';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
    OrderResponse,
    VisitResponse,
    OrderRequest,
} from '../shared/types/order.interface';

export const orderApi = createApi({
    reducerPath: 'orderApi',
    baseQuery: fetchBaseQuery({
        baseUrl: env('API_URL'),
    }),
    tagTypes: ['Order'],
    endpoints: builder => ({
        readOrders: builder.query<OrderResponse[], OrderRequest>({
            query: params => {
                const queryArgs = queryString.stringify(params, {
                    arrayFormat: 'bracket',
                    skipNull: true,
                });
                return {
                    url: `/market/show/orders/history?${queryArgs}`,
                };
            },
            transformResponse: (res: { data: OrderResponse[] }) => {
                return res.data;
            },
        }),

        readOrder: builder.query<OrderResponse, number>({
            query: id => {
                return {
                    url: `/market/show/order/${id}`,
                };
            },
            transformResponse: (res: { data: OrderResponse }) => {
                return res.data;
            },
            providesTags: ['Order'],
        }),

        createVisit: builder.mutation({
            query: body => ({
                url: `/shop/visit`,
                method: 'POST',
                body,
            }),
            transformResponse: (res: { data: VisitResponse }) => {
                return res.data;
            },
        }),
    }),
});

export const {
    useLazyReadOrdersQuery,
    useLazyReadOrderQuery,
    useReadOrderQuery,
    useCreateVisitMutation,
} = orderApi;
