export const text = [
    {
        number: '',
        title: '',
        text: `Our Privacy Policy is designed to provide clarity
                about the information we collect and how we use it
                to provide a better user experience. By accepting our
                Privacy Policy and Terms of Service, you consent to
                our collection, storage, use, and disclosure of your
                personal information as described in this Privacy
                Policy. To the extent our Privacy Policy conflicts with
                the terms of any other agreement entered into
                between us and you or a third party regarding your
                personal information, the terms of any such
                agreement(s) will control. If you have questions or
                complaints regarding our privacy policy or practices,
                please contact us as described in Section 13
                (“Contact Us”).`,
    },
    {
        number: '1.',
        title: 'Introduction',
        text: 'MZHN. operates applications for mobile devices and MZHN. branded websites (the “Service”). In this policy, “MZHN.,” “we” or “us” refers to Bristone Limited and its affiliates, which means subsidiaries, parent companies, joint ventures, and other business entities under common ownership.',

        listTitle: 'Policy describes:',
        list: [
            {
                number: '●',
                text: 'the information we collect, how we do so and why we do it',
            },
            {
                number: '●',
                text: 'how we use and with whom we share such information',
            },

            {
                number: '●',
                text: 'how you can access and update the information we collect about you',
            },

            {
                number: '●',
                text: 'the choices you can make about how we collect, use and share your information',
            },
            {
                number: '●',
                text: 'how we protect the information we store about you',
            },
        ],
    },
    {
        number: '2.',
        title: 'Information We Collect',
        text: 'When you interact with the Service, we may collect and store information about you and your use of the Service as follows.',
        subParagraphs: [
            {
                number: '2.1',
                title: 'Registration Information',
                text: 'We may allow you to “register” with us to create an order for products within the Service. During the registration process, you may be required to provide your first and last names, e-mail address, and other personally identifiable information that helps us deliver you goods.',
            },
            {
                number: '2.2',
                title: 'Information We Receive or Collect When You Use the Service on a Mobile Device',
                text: 'If you access the Service from your mobile telephone or another mobile device (including a tablet) we will collect certain mobile device identifiers, including its MAC address and IP address. We may create and assign to your device an identifier that is similar to an account number. We may also collect geo-location information each time you access the Service from a mobile device unless you have disabled the geo- location feature in the device settings.',
            },
            {
                number: '2.3',
                title: 'Payment Information',
                text: 'If you make a purchase in connection with the Service, we will collect the billing and financial information necessary to process the required payment, which may include your postal and e-mail addresses. Your payment may be processed by a third party, in which case such billing and financial information will be delivered to and processed by such a third party for purposes of completing the payment.',
            },
            {
                number: '2.4',
                title: 'Customer Support Correspondence',
                text: 'When you ask for assistance from our Customer Support team, we will collect and store the contact information you provide (generally your name and e-mail address). We will also store the correspondence and any information contained within.',
            },
            {
                number: '2.5',
                title: 'Notifications, Push Notifications & Local Notifications',
                text: 'The MZHN, Telegram notification program, sends users marketing and promotional messages related to general and personalized sales events and shopping cart status. If you sign up to have Telegram notifications sent directly to your mobile phone, we will store the information you provide, including your telephone number. You may adjust the types of notifications you receive and when you receive them by changing your Telegram notification settings. With your consent, we may send push notifications to your mobile device to provide updates and other relevant messages. You can manage push notifications from the “options” or “settings” page. You may also be able to manage them from your device’s settings page for the Service.',
            },
            {
                number: '2.6',
                title: 'Technical and Usage Information',
                text: 'When you access the Service, we collect certain technical information about your mobile device or computer system, including MAC address, IP address and mobile device ID, and usage statistics about your interactions with the Service. This information is typically collected and analyzed through the use of server log files or web log files (“Log Files”), mobile device software development kits, and tracking technologies like browser cookies. Some of the cookies the Service places on your computer are linked to your user ID number(s). For more information on how we utilize cookies and other tracking technologies please review the “Cookies and Automated Information Collection” portion of Section 3 (“How We Collect Information About You”).',
            },
        ],
    },
    {
        number: '3.',
        title: 'How We Collect Information About You',
        subParagraphs: [
            {
                number: '3.1',
                title: 'Information about Users',
                text: 'When you use the Service, MZHN. collects and stores certain information that you provide directly or through a third-party website. We may also collect information about your interaction with other users of the Service. The bulk of this information is collected and stored through the use of Log Files, which are files on our web servers that record actions taken on our websites.',
            },
            {
                number: '3.2',
                title: 'Communications Features',
                text: 'You may be able to take part in certain activities on our websites or by using the Service that gives you the opportunity to communicate or share information not just with MZHN., but also with other users of our Service. We may record and store archives of these communications on our servers to protect the safety and well- being of our users and our rights and property in connection with the service. You acknowledge and consent to the recording and storage of such communications for these purposes.',
            },
            {
                number: '3.3',
                title: 'Cookies and Automated Information Collection',
                text: 'When you access the Service, we collect certain technical information in order to (i) analyze the usage of our sites and services; (ii) provide a more personalized experience; and (iii) manage advertising. We and service providers acting on our behalf, such as Google Analytics, use Log Files and tracking technologies to collect and analyze certain types of technical information, including cookies, IP addresses, device type, device identifiers, browser types, browser language, referring and exit pages, and URLs, platform type, the number of clicks, domain names, landing pages, pages viewed and the order of those pages, the amount of time spent on particular pages, the date and time of activity on our websites, and other similar information. In some cases, we will associate this information with your user ID number for our internal use. We may also employ other technologies including (i) web beacons, which allow us to know if a certain page was visited or whether an e-mail was opened; (ii) tracking pixels, which allow us to advertise more efficiently by excluding our current users from certain promotional messages, identifying the source of a new installation or delivering ads to you on other websites; and (iii) local shared objects also known as flash cookies, which help us to prevent fraud, remember your in-Service preferences and speed up load times. You can set your web browser to warn you about attempts to place cookies on your computer or limit the type of cookies you allow. Flash cookies operate differently than browser cookies and cookie management tools available in a web browser may not remove flash cookies. If you disable cookies, you may lose some of the features and functionality of the Service, as cookies are necessary to track and enhance your activities within the Service.',
            },
        ],
    },
    {
        number: '4.',
        title: 'How we use the information we collect',
        text: 'In general, we collect, store and use your information to provide you with a safe, efficient, and customized experience. For example, we may use information collected from you in any one or more of the following ways:',
        list: [
            { number: '●', text: 'to create your shipping accounts' },
            {
                number: '●',
                text: 'to provide technical support and respond to user inquiries',
            },
            {
                number: '●',
                text: 'to prevent fraud or potentially illegal activities',
            },
            { number: '●', text: 'to enforce our Terms of Service' },
            { number: '●', text: 'to target and deliver advertising' },
            { number: '●', text: 'to notify users of updates' },
            {
                number: '●',
                text: 'to solicit feedback to improve MZHN. products and services and customize your user experience',
            },
            {
                number: '●',
                text: 'to inform users about new products or promotional offers',
            },
        ],
    },
    {
        number: '5.',
        title: 'Sharing of your information',
        subParagraphs: [
            {
                number: '5.1',
                title: 'Service Providers',
                text: 'We may provide your information to third parties that perform services on our behalf, including payment processing, product delivery, data analysis, e-mail delivery, hosting services, and customer service.',
            },

            {
                number: '5.2',
                title: 'Business Partners',

                text: 'If you access the Service through an offer or link that you received or viewed from a third party or a third party’s website or application, we may share information with that third party about your use of the Service, such as whether and to what extent you used the offer, or purchased merchandise through the Service.',
            },

            {
                number: '5.3',
                title: 'Marketing and Advertising',
                text: 'We may provide information to advertising partners in order to send you communications about MZHN., the Service or to show you specific content tailored to your interests, including relevant advertising materials, and to help us understand how our users interact with advertisements on the Service. The information we share with such advertising partners is in a de-identified format that does not personally identify you.',
            },

            {
                number: '5.4',
                title: 'Other Sharing',
                text: 'In addition to the above, we may also share your information with third parties for the following purposes:',
                list: [
                    {
                        number: '●',
                        text: 'with other users of the Service to encourage interaction among users',
                    },
                    {
                        number: '●',
                        text: 'to allow a merger, acquisition, or sale of MZHN. assets',
                    },
                    {
                        number: '●',
                        text:
                            'to respond appropriately to legal process if we have a good faith belief that it is necessary to do so; to' +
                            'comply with mandatory requirements of applicable law; to protect the safety of any person; to protect our' +
                            'own rights and property, including our Terms of Service and other terms you have agreed to; to address' +
                            'fraud, security or technical issues; and',
                    },

                    {
                        number: '●',
                        text: 'to publish anonymous or aggregate information about MZHN. or the Service',
                    },
                ],
            },
        ],
    },
    {
        number: '6.',
        title: 'Our policies concerning children',
        text: 'The Service is not intended for use by children under the age of 18. We do not knowingly collect information from children under the age of 18. If you are under the age of 18, please do not use the Service and do not provide any information to us. In the event that we learn that we have inadvertently gathered personal information from children under the age of 18, we will take reasonable measures to promptly erase such information from our records.',
    },
    {
        number: '7.',
        title: 'How to access and update your information',
        text: 'If you no longer want MZHN. to make active use of your information, you may send an e-mail to our Support Team at the address support@mozhno.store with the text “Delete My Data” in the subject line and include your first name, last name, and e-mail address. We will respond to your request within 30 days. Please note that certain records, for example, those pertaining to payments or customer service matters, will be retained for legal and accounting purposes.',
    },
    {
        number: '8.',
        title: 'Receiving and using foreign data',
        text: 'MZHN. reserves the right to transfer, process, and store information about our users on servers located in any country. MZHN. may also subcontract processing to, or share your information with, third parties located in countries other than the one in which you reside. This means that your personal information may be subject to privacy laws different from those in your home country. For example, information collected within the European Economic Area (“EEA”) and Switzerland may be transferred to and processed by MZHN. or third parties in a country outside the EEA or Switzerland, where you may have fewer rights regarding the use and sharing of your information. MZHN. will use and share your information only in accordance with the terms of this Privacy Policy.',
    },
    {
        number: '9.',
        title: 'Links',
        text: 'We may display content that links to third-party websites. We are not responsible for third parties’ data privacy practices and content. For more information, please consult the privacy policies of any such third- party websites.',
    },
    {
        number: '10.',
        title: 'Your sharing and messaging options',
        subParagraphs: [
            {
                number: '10.1',
                title: 'Opting out of Promotional Communications',

                text: 'You may opt out of receiving additional promotional e-mails from MZHN. by clicking on the “unsubscribe” link in any such e-mail or visiting the e-mail preference page. Please note that once we receive your request, it may take an additional period of time to process your opt-out request.',
            },
            {
                number: '10.2',
                title: 'Opting Out of Other Communications',
                text: 'You may elect to receive text messages from us via Telegram. When you sign up to receive text messages, we may send you information about promotional offers and more. These messages may use information automatically collected based on your actions while on our sites and may prompt messaging such as cart abandon messages (IE cookies). To the extent you voluntarily opt to have text notifications sent directly to your mobile phone, we receive and store the information you provide, including your telephone number or when you read a text message. Message frequency may vary. You may opt out of receiving text messages at any time by texting “STOP” to our text messages. When we receive an opt-out message from you for text messages, we will send a message confirming our receipt of your opt-out. After that, you will not receive further text messages from MZHN.',
            },
        ],
    },
    {
        number: '11.',
        title: 'Security of your information',
        text: 'MZHN. implements commercially reasonable security measures to protect the security of your information both online and offline, and we are committed to the protection of customer information. The profile information associated with a MZHN. user account is protected by the password each member uses to access their online account. It is important that you protect and maintain the security of your login information and that you immediately notify us of any unauthorized use of your account. If you forget the password for your MZHN. account, the website allows you to request instructions that explain how to reset your password. When you sign in to your account or enter payment information (such as a credit card number when purchasing virtual currency), we encrypt the transmission of that information using secure socket layer technology (“SSL”). While we take reasonable precautions against possible security breaches of our websites and our customer databases and records, no website or Internet transmission is completely secure, and we cannot guarantee that unauthorized access, hacking, data loss, or other breaches will never occur. We urge you to take steps to keep your personal information safe (including your account password), and to log out of your account after use. Unfortunately, the transmission of information over the Internet is not completely secure. Although we strive to protect your personal data, we cannot guarantee the security of your data while it is being transmitted to our site; any transmission is at your own risk. Once we have received your information, we have procedures and security features in place to try to prevent unauthorized access.',
    },
    {
        number: '12.',
        title: 'Changes to our privacy policy',
        text: 'If we decide to make material changes to our Privacy Policy, we will notify you and other users by placing a notice on our website or by sending you a notice to the e-mail address we have on file for you. You should periodically check https://mozhno.store and this privacy page for updates',
    },
    {
        number: '13.',
        title: 'Contact us',
        text: 'If you have any questions, comments, or concerns regarding our Privacy Policy and/or practices, please send an e-mail to us.',
    },
];
