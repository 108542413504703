import React, { memo } from 'react';

import Page from 'shared/ui/Page';
import P from 'shared/ui/P';
import { ParagraphSize } from 'shared/types/typography.interface';
import Unit from 'shared/ui/Unit';
import { CartUnit } from 'features/Cart/ui/CartUnit/ui/CartUnit';
import { ProductList } from 'shared/types/order.interface';

interface ProductsListTypes {
    readonly items: ProductList[];
}

const ProductsList = memo((props: ProductsListTypes) => {
    const { items } = props;
    return (
        <Page>
            <P size={ParagraphSize.h2} text={'Items'} />

            {items?.map(product => (
                <Unit key={product.id}>
                    <CartUnit {...product} />
                </Unit>
            ))}
        </Page>
    );
});

export default ProductsList;
