export const infoText = [
    { key: 'line-1', text: 'Just wait. We’re building your payment page.' },
    /*{
        key: 'line-2',
        text: 'Please do not reload the page. This will result in data loss.',
    },*/
];

export const centered = {
    justify: 'center',
    align: 'center',
};

export const gap = 8;
