import env from '@beam-australia/react-env';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import queryString from 'query-string';

import { CatalogType } from '../shared/types/catalog.interface';
import { Product } from '../shared/types/product.interfce';
import { Countries } from '../shared/types/utils.interface';

export const catalogApi = createApi({
    reducerPath: 'catalogApi',
    baseQuery: fetchBaseQuery({
        baseUrl: env('GOAPI_URL'),
    }),
    tagTypes: ['Catalog'],
    endpoints: builder => ({
        readCatalog: builder.query({
            keepUnusedDataFor: 3600,
            query: ({ id, args }) => {
                const queryArgs = queryString.stringify(args, {
                    arrayFormat: 'bracket',
                    skipNull: true,
                });
                return {
                    url: `/public/products/${id}?${queryArgs}`,
                    method: 'GET',
                };
            },
            transformResponse: (response: { data: Array<Product> }, meta) => {
                return {
                    items: response.data,
                    total:
                        Number(meta.response.headers.get('Items-Count')) || 0,
                };
            },
            providesTags: ['Catalog'],
        }),

        readCategories: builder.query({
            keepUnusedDataFor: 3600,
            query: params => {
                return {
                    url: `/shops/${params.shop_id}/categories`,
                };
            },
            transformResponse: (res: { data: CatalogType[] }, meta) => {
                return {
                    items: res.data || [],
                    total:
                        Number(meta.response.headers.get('Items-Count')) || 0,
                };
            },
            providesTags: ['Catalog'],
        }),

        readCountries: builder.query<Countries[], number>({
            keepUnusedDataFor: 3600,
            query: () => {
                return {
                    url: `/delivery-list`,
                    method: 'GET',
                };
            },
            transformResponse: (res: { data: Countries[] }) => {
                return res.data;
            },
            providesTags: ['Catalog'],
        }),
    }),
});

export const { useLazyReadCountriesQuery } = catalogApi;
