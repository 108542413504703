import React, { useEffect } from 'react';
import {
    useBackButton,
    useCloudStorage,
    useHapticFeedback,
} from '@telegram-apps/sdk-react';
import { useNavigate } from 'react-router-dom';

interface BackButton {
    readonly isHide?: boolean;
}
export const Back = (props: BackButton) => {
    const { isHide } = props;
    const cloudStorage = useCloudStorage(true);
    const navigate = useNavigate();
    const backButton = useBackButton(true);
    const hapticFeedback = useHapticFeedback(true);

    const handleNavigate = () => {
        cloudStorage
            .get('backRoute')
            .then(backRoute =>
                backRoute.length < 2 ? navigate(-1) : navigate(backRoute),
            )
            .then(() => cloudStorage.delete('backRoute'));
        hapticFeedback.impactOccurred('medium');
    };

    useEffect(() => {
        if (isHide && backButton) {
            return backButton.hide();
        }

        if (backButton) {
            backButton.show();
            return backButton.on('click', handleNavigate, true);
        }
    }, [backButton, isHide]);

    return <></>;
};
