import React, { memo, useContext } from 'react';
import { Flex, Skeleton } from 'antd';

import P from 'shared/ui/P';
import { ParagraphSize } from 'shared/types/typography.interface';
import { PaymentInfoType } from 'shared/types/utils.interface';
import Price from 'features/Product/ui/Price';
import { gap } from 'features/Checkout/ui/Loader/lib/options';

import { DiscountContext } from '../../../features/Utils/lib/Providers/lib/DiscountContext';

export const PaymentInfo = memo((props: PaymentInfoType) => {
    const { subtotal, delivery, isShowUSDTTotal = false } = props;
    const { discount, loading } = useContext(DiscountContext);

    const USDTSubotalWithDiscount = subtotal - (subtotal / 100) * discount;
    const USDTTotal = USDTSubotalWithDiscount + delivery;

    const roundedUSDTTotalWithSeparators = (
        Math.ceil(USDTTotal * 100) / 100
    ).toLocaleString('en-US');

    const isComponentLoaded = !(loading && subtotal && delivery);

    return (
        <Flex vertical gap={8}>
            <Flex justify={'space-between'} align={'center'}>
                {isComponentLoaded ? (
                    <P
                        type={'secondary'}
                        size={ParagraphSize.body}
                        text={'Subtotal'}
                    />
                ) : (
                    <Skeleton.Input active size={'small'} />
                )}

                {isComponentLoaded ? (
                    <Price
                        weight={400}
                        size={ParagraphSize.body}
                        value={subtotal}
                    />
                ) : (
                    <Skeleton.Button active size={'small'} />
                )}
            </Flex>

            {discount > 0 && (
                <Flex justify={'space-between'} align={'center'}>
                    {isComponentLoaded ? (
                        <P
                            type={'secondary'}
                            size={ParagraphSize.body}
                            text={`Discount  ${discount}%`}
                        />
                    ) : (
                        <Skeleton.Input active size={'small'} />
                    )}
                    {isComponentLoaded ? (
                        <div>
                            <P size={ParagraphSize.body} text={'– '} />
                            <Price
                                weight={400}
                                size={ParagraphSize.body}
                                value={(subtotal / 100) * discount}
                            />
                        </div>
                    ) : (
                        <Skeleton.Button active size={'small'} />
                    )}
                </Flex>
            )}

            <Flex justify={'space-between'} align={'center'}>
                {isComponentLoaded ? (
                    <P
                        type={'secondary'}
                        size={ParagraphSize.body}
                        text={'Delivery'}
                    />
                ) : (
                    <Skeleton.Input active size={'small'} />
                )}

                {isComponentLoaded ? (
                    <Price
                        weight={400}
                        value={+delivery}
                        size={ParagraphSize.body}
                    />
                ) : (
                    <Skeleton.Button active size={'small'} />
                )}
            </Flex>

            <Flex vertical style={{ marginTop: gap / 2 }}>
                <Flex justify={'space-between'} align={'center'}>
                    <P weight={590} size={ParagraphSize.h3} text={'Total'} />
                    {isComponentLoaded ? (
                        <Price
                            weight={590}
                            size={ParagraphSize.h3}
                            value={USDTTotal}
                        />
                    ) : (
                        <Skeleton.Button active size={'small'} />
                    )}
                </Flex>

                {isComponentLoaded && isShowUSDTTotal && (
                    <Flex justify={'flex-end'}>
                        <P
                            type={'secondary'}
                            size={ParagraphSize.body}
                            text={`≈ $${roundedUSDTTotalWithSeparators}`}
                        />
                    </Flex>
                )}
            </Flex>
        </Flex>
    );
});
