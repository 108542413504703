import React, { memo } from 'react';
import { Flex, Skeleton } from 'antd';

import { ParagraphSize } from 'shared/types/typography.interface';
import P from 'shared/ui/P';
import { gap } from 'shared/config/theme/constants';
import Back from 'shared/ui/Back';

interface ErrorHeaderTypes {
    readonly id: number;
    readonly isLoading: boolean;
}
export const ErrorHeader = memo((props: ErrorHeaderTypes) => {
    const { id, isLoading } = props;

    const Alert = () => {
        return (
            <svg
                xmlns='http://www.w3.org/2000/svg'
                width='90'
                height='90'
                viewBox='0 0 90 90'
                fill='none'
            >
                <path
                    d='M81.8182 81.8181H8.18182V77.7272H4.09091V61.3636H8.18182V53.1818H12.2727V44.9999H16.3636V36.8181H20.4545V28.6363H24.5455V20.4545H28.6364V12.2727H32.7273V8.18176H57.2727V12.2727H61.3636V20.4545H65.4545V28.6363H69.5455V36.8181H73.6364V44.9999H77.7273V53.1818H81.8182V61.3636H85.9091V77.7272H81.8182V81.8181ZM36.8182 24.5454H32.7273V32.7272H28.6364V40.909H24.5455V49.0909H20.4545V57.2727H16.3636V65.4545H12.2727V73.6363H77.7273V65.4545H73.6364V57.2727H69.5455V49.0909H65.4545V40.909H61.3636V32.7272H57.2727V24.5454H53.1818V16.3636H36.8182V24.5454ZM40.9091 28.6363H49.0909V53.1818H40.9091V28.6363ZM40.9091 57.2727H49.0909V65.4545H40.9091V57.2727Z'
                    fill='#636366'
                />
            </svg>
        );
    };

    return (
        <>
            <Flex justify={'center'} align={'center'} vertical gap={gap * 3}>
                <Flex vertical gap={gap * 2} align={'center'}>
                    <Alert />
                    <Flex vertical align={'center'}>
                        <P size={ParagraphSize.body} text={'Oops!'} />
                        <P
                            size={ParagraphSize.body}
                            text={'Something goes wrong with Wallet!'}
                        />
                    </Flex>
                </Flex>

                <Flex vertical={false} align={'center'} gap={8}>
                    <P
                        type={'secondary'}
                        size={ParagraphSize.h3}
                        text={'Order number:'}
                    />

                    {isLoading && !id ? (
                        <Skeleton.Button
                            style={{ width: '50px' }}
                            size={'small'}
                            active={isLoading}
                            shape={'square'}
                        />
                    ) : (
                        <P
                            type={'secondary'}
                            size={ParagraphSize.h3}
                            text={`${id}`}
                        />
                    )}
                </Flex>
            </Flex>
            <Back />
        </>
    );
});
