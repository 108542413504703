import React from 'react';
import { Flex } from 'antd';

import P from 'shared/ui/P';
import { ParagraphSize } from 'shared/types/typography.interface';
import { DocumentListType } from 'shared/types/utils.interface';

export const DocumentList = ({ listTitle, list }: DocumentListType) => {
    return (
        <Flex vertical gap={4}>
            {listTitle && (
                <P size={ParagraphSize.secondaryBody} text={listTitle} />
            )}
            {list.map(listItem => (
                <Flex key={listItem.number} vertical={false} gap={10}>
                    <P size={ParagraphSize.body} text={listItem.number} />
                    <P
                        size={ParagraphSize.secondaryBody}
                        text={listItem.text}
                    />
                </Flex>
            ))}
        </Flex>
    );
};
