import React, { Suspense, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { useInitData, useMiniApp } from '@telegram-apps/sdk-react';

import Page from 'shared/ui/Page';
import Unit from 'shared/ui/Unit';
import Wrapper from 'shared/ui/Wrapper';
import { OrderInList } from 'features/Order/ui/OrderInList/ui/OrderInList';
import Empty from 'features/Order/ui/Empty';
import { OrdersLoader } from 'features/Order/ui/OrdersLoader/ui/OrdersLoader';
import { Header } from 'wigets/orders/header/header';
import Back from 'shared/ui/Back';
import { ShopId } from 'shared/config/options';
import { Screen } from 'shared/ui/Screen/ui/Screen';

import { useLazyReadOrdersQuery } from '../../../api/order.api';

export const OrdersPage = () => {
    const navigate = useNavigate();
    const miniApp = useMiniApp(true);
    const initData = useInitData(true);

    const [getOrders, { data: orders, isLoading }] = useLazyReadOrdersQuery();

    const handleViewOrder = (orderId: number) => {
        navigate(`${orderId}`);
    };

    useEffect(() => {
        if (miniApp && initData) {
            getOrders({
                shop_id: ShopId(),
                telegram_user_id: initData?.user.id,
            });
        }
    }, [miniApp, initData]);

    return (
        <Screen>
            <Wrapper position={'first'}>
                <Page>
                    <Header />

                    <Suspense fallback={<OrdersLoader />}>
                        {isLoading ? (
                            <OrdersLoader />
                        ) : orders?.length < 1 ? (
                            <Empty />
                        ) : (
                            orders?.map(order => (
                                <FadeIn key={order.order_id}>
                                    <Unit
                                        onClick={() =>
                                            handleViewOrder(order.order_id)
                                        }
                                    >
                                        <OrderInList {...order} />
                                    </Unit>
                                </FadeIn>
                            ))
                        )}
                    </Suspense>
                </Page>
            </Wrapper>
            <Back />
        </Screen>
    );
};

const fadeIn = keyframes`
    0% { opacity: 0.2; }
    15% {opacity: 0.4;}
    35% {opacity: 0.5;}
    50% {opacity: .6;}
    75% {opacity: 0.75;}
    100% { opacity: 1; }
`;

const FadeIn = styled.div`
    animation: ${fadeIn} 1s linear;
`;
