import React, { useEffect, useState } from 'react';
import { useInitData } from '@telegram-apps/sdk-react';
import { ReactTagManager } from 'react-gtm-ts';

import Wrapper from 'shared/ui/Wrapper';
import Page from 'shared/ui/Page';
import { Title } from 'wigets/home/title/title';
import { gap } from 'shared/config/theme/constants';
import { checkCollectionExpired } from 'shared/lib/checkCollectionExpired';
import Footer from 'features/Catalog/ui/Footer';
import Back from 'shared/ui/Back';
import Categories from 'wigets/home/categories/categories';
import MyOrdersAndBagButtons from 'shared/ui/MyOrdersAndBagButtons';

import { Screen } from '../../../shared/ui/Screen/ui/Screen';

const HomePageTitle = 'Drops & Collections';

export const HomePage = () => {
    const initData = useInitData(true);
    const [expired, setIsExpired] = useState(false);

    useEffect(() => {
        if (initData) {
            ReactTagManager.action({
                event: 'user_id_set',
                telegram_id: initData?.user.id,
            });
        }
    }, [initData]);

    useEffect(() => {
        setIsExpired(checkCollectionExpired());
    }, []);

    return (
        <Screen>
            <Wrapper
                position={'first'}
                style={{
                    justifyContent: 'space-between',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Page>
                    <MyOrdersAndBagButtons />
                    <Title text={HomePageTitle} />
                    <Categories isCollectionExpired={expired} />
                </Page>

                <Page style={{ marginTop: gap * 3 }}>
                    <Footer />
                </Page>
            </Wrapper>

            <Back isHide />
        </Screen>
    );
};
