import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { useHapticFeedback } from '@telegram-apps/sdk-react';
import { Button } from 'antd';

interface MainButtonTypes {
    readonly disabled?: boolean;
    readonly onClick: () => void;
    readonly children: ReactNode;
}
export const MainButton = (props: MainButtonTypes) => {
    const { disabled, onClick, children } = props;
    const hapticFeedback = useHapticFeedback(true);

    return (
        <StyledButton
            block
            type={'primary'}
            disabled={disabled}
            onClick={() => {
                onClick();
                hapticFeedback.impactOccurred('medium');
            }}
        >
            {children}
        </StyledButton>
    );
};

const StyledButton = styled(Button)`
    box-shadow: none;
    outline: none;
    border-radius: 12px;
    font-style: normal;
    &:hover {
        color: #000000 !important;
    }
    background: ${props => props?.disabled && 'rgb(40, 40, 42)'};
`;
