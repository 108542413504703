import { combineReducers } from 'redux';

import { catalogApi } from '../../api/catalog.api';
import { productApi } from '../../api/product.api';
import { cartApi } from '../../api/cart.api';
import { orderApi } from '../../api/order.api';
import { checkoutApi } from '../../api/checkout.api';

const rootReducer = combineReducers({
    [catalogApi.reducerPath]: catalogApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [cartApi.reducerPath]: cartApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [checkoutApi.reducerPath]: checkoutApi.reducer,
});

export default rootReducer;
