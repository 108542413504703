import env from '@beam-australia/react-env';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const cartApi = createApi({
    reducerPath: 'cartApi',
    baseQuery: fetchBaseQuery({
        baseUrl: env('API_URL'),
    }),
    tagTypes: ['Cart'],

    endpoints: builder => ({
        updateCart: builder.mutation<void, any>({
            query: body => ({
                url: `/market/card/update`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Cart'],
        }),
    }),
});

export const { useUpdateCartMutation } = cartApi;
