import React, { useEffect } from 'react';
import {
    useClosingBehavior,
    useMiniApp,
    useSwipeBehavior,
    useViewport,
} from '@telegram-apps/sdk-react';
import { ReactTagManager } from 'react-gtm-ts';
import ReactGA from 'react-ga4';
import env from '@beam-australia/react-env';

import useIsomorphicLayoutEffect from 'shared/hooks/useIsomorphicLayoutEffect';
import { blackColor } from 'shared/config/theme/constants';

const TelegramInit = () => {
    const isProd = env('ENVIRONMENT') === 'prod';

    const swipeBehavior = useSwipeBehavior(true);
    const miniApp = useMiniApp(true);
    const viewport = useViewport(true);
    const closingBehavior = useClosingBehavior(true);

    useEffect(() => {
        if (viewport) {
            viewport.expand();
        }
    }, [viewport]);

    useEffect(() => {
        if (miniApp) {
            miniApp.setHeaderColor('#0F0F0F');
            miniApp.setBgColor('#0F0F0F');
        }
    }, [miniApp]);

    useEffect(() => {
        if (swipeBehavior && swipeBehavior.supports('disableVerticalSwipe')) {
            swipeBehavior?.disableVerticalSwipe();
        }
    }, [swipeBehavior]);

    useEffect(() => {
        if (closingBehavior) {
            closingBehavior.enableConfirmation();
        }
    }, [closingBehavior]);

    useIsomorphicLayoutEffect(() => {
        if (isProd) {
            ReactTagManager.init({
                code: 'GTM-M5JVFMNQ',
                debug: !isProd,
                performance: !isProd,
            });
            ReactGA.initialize('G-8ZWJ2VYYRP');
        }
    }, [isProd]);

    return <></>;
};

export default TelegramInit;
