import { RouteObject } from 'react-router-dom';

import CatalogFeature from './features/Catalog';
import ProductFeature from './features/Product';
import CheckoutFeature from './features/Checkout';
import CartFeature from './features/Cart';
import OrdersFeature from './features/Order';
import CommonLayout from './shared/layout/CommonLayout';
import UtilsFeature from './features/Utils';
import HomePage from './pages/home';
import { get } from './features/Home/model/get';

export const routes: RouteObject[] = [
    {
        path: '/',
        element: <CommonLayout />,
        children: [
            {
                path: 'home',
                element: <HomePage />,
                loader: async () => {
                    return {
                        categories: await get(),
                    };
                },
            },
            {
                path: 'health',
                element: <p>I'm healthy</p>,
            },
            ...CatalogFeature.routes,
            ...ProductFeature.routes,
            ...CheckoutFeature.routes,
            ...CartFeature.routes,
            ...OrdersFeature.routes,
            ...UtilsFeature.routes,
        ],
    },
];
