import React, { memo, useContext, useEffect, useState } from 'react';
import { Flex, Skeleton } from 'antd';
import styled, { keyframes } from 'styled-components';

import { buildFilePath } from 'shared/lib/buildFilePath';
import P from 'shared/ui/P';
import { ParagraphSize } from 'shared/types/typography.interface';
import { getCoverImage } from 'shared/lib/getCoverImage';
import { Product } from 'shared/types/product.interfce';
import Discount from 'shared/ui/Discount';

import Price from '../../Price';
import { LayoutContext } from '../../../../Utils/lib/Providers/lib/LayoutContext';
import { DiscountContext } from '../../../../Utils/lib/Providers/lib/DiscountContext';

interface ProductInListProps extends Product {
    readonly onClick: (id: number) => void;
}

export const ProductInList = memo((props: ProductInListProps) => {
    const { colWidth } = useContext(LayoutContext);
    const { discount } = useContext(DiscountContext);
    const elementRef = React.useRef<HTMLImageElement>(null);
    const [ready, setReady] = useState(false);
    const { id, images, title, price, onClick } = props;

    useEffect(() => {
        const element = elementRef.current;
        if (!element) {
            return;
        }

        const observer = new IntersectionObserver(
            entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const ele = entry.target;
                        const src = ele.getAttribute('data-background-src');
                        ele.removeAttribute('data-background-src');
                        ele.style.backgroundImage = `url(${src})`;
                        observer.unobserve(ele);
                        setReady(true);
                    }
                });
            },
            {
                threshold: 0,
            },
        );
        observer.observe(element);

        return () => {
            observer.unobserve(element);
        };
    }, []);

    return (
        <StyledWrapper vertical gap={12} onClick={() => onClick(+id)}>
            {!ready && (
                <Skeleton.Image
                    style={{ width: colWidth - 8, height: colWidth * 1.18 }}
                />
            )}

            <StyledImage
                ref={elementRef}
                style={{
                    width: colWidth - 8,
                    height: colWidth * 1.18,
                }}
                data-background-src={buildFilePath(getCoverImage(images))}
            />

            <Flex vertical gap={4}>
                <P size={ParagraphSize.body} text={title} />

                {discount > 0 ? (
                    <Discount.Catalog discount={discount} price={price} />
                ) : (
                    <Price
                        style={{ color: 'rgba(235, 235, 245, 0.60)' }}
                        weight={500}
                        size={ParagraphSize.body}
                        type={'secondary'}
                        value={price}
                    />
                )}
            </Flex>
        </StyledWrapper>
    );
});

const StyledWrapper = styled(Flex)`
    cursor: pointer;
`;

const fadeIn = keyframes`
    0% { opacity: 0.1; }
    25% { opacity: 0.15; }
    50% { opacity: 0.45; }
    75% { opacity: 0.7; }
    100% { opacity: 1; }
`;

const StyledImage = styled.div`
    border-radius: 10px;
    animation: ${fadeIn} 1.25s ease-in;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
`;
