import React, { memo, useEffect } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';

import Timer from 'features/Home/ui/Timer';
import { targetDate } from 'features/Home/ui/Timer/lib/options';
import notoriginal from 'shared/static/images/notoriginal.png';
import notagame from 'shared/static/images/notagame.png';
import CategoryInHome from 'features/Home/ui/CategoryInHome';

import useSessionStorage from '../../../shared/hooks/useSessionStorage';
import { CatalogType } from '../../../shared/types/catalog.interface';

type CategoryList = {
    isCollectionExpired?: boolean;
};

const Categories = memo((props: CategoryList) => {
    const { isCollectionExpired } = props;
    const navigate = useNavigate();
    const [storage, setStorage] = useSessionStorage('mainCategoryId', null);

    const { categories } = useLoaderData() as { categories: CatalogType[] };

    useEffect(() => {
        if (categories) {
            setStorage(categories?.data?.[0]?.id);
        }
    }, [categories]);

    const handleViewCategory = (
        activeCategoryId: number,
        connectedCategoryId: number,
    ) => {
        navigate(`/catalog/${activeCategoryId}/${connectedCategoryId}`, {
            state:
                activeCategoryId === categories?.data?.[0]?.id
                    ? { isGif: 1 }
                    : {},
        });
    };

    return (
        <>
            {!isCollectionExpired && (
                <CategoryInHome
                    title={categories?.data?.[0]?.name}
                    description={'1st NOT LIMITED(!) DROP by Notcoin X MOZHNO.'}
                    image={notagame}
                    component={<Timer targetDate={targetDate()} />}
                    onClick={() =>
                        handleViewCategory(
                            categories?.data?.[0]?.id,
                            categories?.data?.[1]?.id,
                        )
                    }
                />
            )}
            <CategoryInHome
                title={categories?.data?.[1]?.name}
                description={'Native collection. Probably nothing…'}
                image={notoriginal}
                // component={
                //     <ItemCounter count={categories?.[1]?.product_count} />
                // }
                onClick={() =>
                    handleViewCategory(
                        categories?.data?.[1]?.id,
                        categories?.data?.[0]?.id,
                    )
                }
            />
        </>
    );
});

export default Categories;
