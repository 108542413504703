import type { RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';

import {
    CheckoutConfirmPage,
    CheckoutErrorPage,
    CheckoutPage,
    CheckoutStatusPage,
} from '../../pages/checkout';

const routes: RouteObject[] = [
    {
        path: 'checkout',
        element: <Outlet />,
        children: [
            { path: '', element: <CheckoutPage /> },
            { path: 'confirm', element: <CheckoutConfirmPage /> },
            { path: 'checkout-error/:id', element: <CheckoutErrorPage /> },
        ],
    },
    {
        path: 'status/:id',
        element: <CheckoutStatusPage />,
    },
];

export default routes;
