import React from 'react';
import { Skeleton } from 'antd';

import Unit from '../../../../../shared/ui/Unit';

export const OrdersLoader = () => {
    const SkeletonLoadingCount = [
        { key: '1' },
        { key: '2' },
        { key: '3' },
        { key: '4' },
        { key: '5' },
        { key: '6' },
    ];

    const blockWidth = 200;

    return (
        <>
            {SkeletonLoadingCount.map(s => (
                <Unit key={s.key}>
                    <Skeleton
                        style={{ width: blockWidth + 150 }}
                        paragraph={{ rows: 1 }}
                    />
                    <Skeleton.Button
                        style={{ width: blockWidth }}
                        size={'small'}
                        shape={'square'}
                        block={false}
                    />
                </Unit>
            ))}
        </>
    );
};
