import React, { useEffect, useState } from 'react';
import { Flex, Typography } from 'antd';

import { Paragraph, ParagraphSize } from '../../../types/typography.interface';

const { Text, Title } = Typography;

export const P = (props: Paragraph) => {
    const { size, text, weight, type, style, isLink } = props;
    const [node, setNode] = useState(null);

    const styles = {
        textDecoration: isLink && 'underline',
        cursor: isLink ? 'pointer' : 'default',
        fontWeight: weight,
        textUnderlineOffset: '3px',
        ...style,
    };

    useEffect(() => {
        switch (size) {
            case ParagraphSize.h1:
                //28
                return setNode(
                    <Title style={styles} level={1}>
                        {text}
                    </Title>,
                );
            case ParagraphSize.h2:
                //20
                return setNode(
                    <Title style={styles} level={2}>
                        {text}
                    </Title>,
                );
            case ParagraphSize.h3:
                //17
                return setNode(
                    <Text type={type} style={{ fontSize: '17px', ...styles }}>
                        {text}
                    </Text>,
                );
            case ParagraphSize.caption:
                //13
                return setNode(
                    <Title type={type} style={styles} level={4}>
                        {text}
                    </Title>,
                );
            case ParagraphSize.body:
                //15
                return setNode(
                    <Text type={type} style={styles}>
                        {props?.extra ? (
                            <Flex align={'center'} gap={8}>
                                {text}
                                {props.extra}
                            </Flex>
                        ) : (
                            text
                        )}
                    </Text>,
                );
            case ParagraphSize.secondaryBody:
                //13
                return setNode(
                    <Text
                        type={type || 'secondary'}
                        style={{
                            fontSize: '13px',
                            lineHeight: '20px',
                            ...styles,
                        }}
                    >
                        {text}
                    </Text>,
                );
        }
    }, [size, text]);

    return node;
};
