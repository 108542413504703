import { useEffect, useState } from 'react';

const isServer: boolean = typeof window === 'undefined';

export default function useSessionStorage<T>(
    key: string,
    initialValue: T,
): [T, (value: T | ((prevValue: T) => T)) => void] {
    const [storedValue, setStoredValue] = useState<T>(() => {
        if (isServer) {
            return initialValue;
        }
        try {
            const item = window.sessionStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            return initialValue;
        }
    });

    const initialize = (): T => {
        if (isServer) {
            return initialValue;
        }
        try {
            const item = window.sessionStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            return initialValue;
        }
    };

    useEffect(() => {
        if (!isServer) {
            setStoredValue(initialize());
        }
    }, []);

    const setValue = (value: T | ((prevValue: T) => T)): void => {
        try {
            const valueToStore: T =
                typeof value === 'function'
                    ? (value as (prevValue: T) => T)(storedValue)
                    : value;
            setStoredValue(valueToStore);
            if (typeof window !== 'undefined') {
                window.sessionStorage.setItem(
                    key,
                    JSON.stringify(valueToStore),
                );
            }
        } catch (error) {
            console.log(error);
        }
    };

    return [storedValue, setValue];
}
