import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Flex } from 'antd';

import P from '../P';
import { ParagraphSize } from '../../types/typography.interface';
import Price from '../../../features/Product/ui/Price';

type DiscountProps = {
    readonly price: number;
    readonly discount: number;
};

const Discount = ({ children }: PropsWithChildren) => {
    const subComponents = React.Children.map(children, child => {
        return React.cloneElement(child);
    });

    return (
        <StyledDiscount>
            {subComponents.map((component, index) => (
                <React.Fragment key={index}>{component}</React.Fragment>
            ))}
        </StyledDiscount>
    );
};

const StyledDiscount = styled.div`
    text-decoration: line-through;
`;

const Product = ({ price, discount }: DiscountProps) => (
    <Flex gap={4} vertical>
        <Price
            size={ParagraphSize.h2}
            value={price - (price / 100) * discount}
        />

        <Price
            style={{
                textDecoration: 'line-through',
            }}
            weight={400}
            size={ParagraphSize.body}
            value={price}
        />
    </Flex>
);
Discount.Product = Product;

const Catalog = ({ price, discount }: DiscountProps) => (
    <Flex gap={4} align={'center'}>
        <Price
            style={{ color: 'rgba(235, 235, 245, 0.60)' }}
            weight={500}
            size={ParagraphSize.body}
            value={price - (price / 100) * discount}
        />

        <Price
            style={{
                fontSize: 12,
                textDecoration: 'line-through',
                color: '#515154',
            }}
            type={'secondary'}
            weight={400}
            size={ParagraphSize.body}
            value={price}
        />
    </Flex>
);
Discount.Catalog = Catalog;

const Cart = ({ price, discount }: DiscountProps) => (
    <Flex gap={4} justify={'space-between'}>
        <P
            type={'secondary'}
            size={ParagraphSize.body}
            text={`Discount ${discount}%`}
        />

        <Flex vertical={false} gap={2}>
            <P weight={400} size={ParagraphSize.body} text={'– '} />
            <Price
                weight={400}
                size={ParagraphSize.body}
                value={(price / 100) * discount}
            />
        </Flex>
    </Flex>
);
Discount.Cart = Cart;

const CartUnit = ({ price, discount }: DiscountProps) => (
    <Flex gap={4} justify={'space-between'}>
        <Price
            weight={600}
            size={ParagraphSize.body}
            value={price - (price / 100) * discount}
        />

        <Price
            style={{
                textDecoration: 'line-through',
                color: '#515154',
            }}
            type={'secondary'}
            weight={400}
            size={ParagraphSize.body}
            value={price}
        />
    </Flex>
);
Discount.CartUnit = CartUnit;
export default Discount;
