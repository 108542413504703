import styled from 'styled-components';
import { CSSProperties, memo, ReactNode } from 'react';

interface WrapperTypes {
    readonly children: ReactNode;
    readonly position?: string;
    readonly style?: CSSProperties;
}

export const Wrapper = memo((props: WrapperTypes) => {
    const { children, position, style } = props;
    return (
        <StyledWrapper style={{ ...style }} $position={position}>
            {children}
        </StyledWrapper>
    );
});

const StyledWrapper = styled.div<{ $position: string }>`
    padding: 0 16px;
    margin-top: ${props => (props.$position === 'first' ? '16px' : 0)};
`;
