import React, { memo } from 'react';

import P from 'shared/ui/P';
import { ParagraphSize } from 'shared/types/typography.interface';
import Unit from 'shared/ui/Unit';
import Page from 'shared/ui/Page';
import { DeliveryInfoTypes } from 'shared/types/checkout.interface';

export const DeliveryInfo = memo((props: DeliveryInfoTypes) => {
    const { address, full_name, postal_code, country } = props;

    const pageTitle = 'Delivery Address';

    return (
        <Page>
            <P size={ParagraphSize.h2} text={pageTitle} />
            <Unit>
                <P weight={590} size={ParagraphSize.body} text={full_name} />
                <P size={ParagraphSize.body} text={address} />
                <P size={ParagraphSize.body} text={postal_code} />
                <P size={ParagraphSize.body} text={country} />
            </Unit>
        </Page>
    );
});
