import React, { memo, ReactNode } from 'react';
import { FloatButton } from 'antd';

import { blackColor } from 'shared/config/theme/constants';
import { useCart } from 'shared/hooks/useCart';

interface Cart {
    readonly onClick: () => void;
    readonly icon: ReactNode;
}

export const CartIcon = memo((props: Cart) => {
    const { onClick, icon } = props;

    const { count } = useCart();

    return (
        <FloatButton
            style={{
                width: '52px',
                height: '52px',
            }}
            icon={icon}
            onClick={onClick}
            shape='circle'
            type={'primary'}
            badge={{
                count: count,
                color: blackColor,
                size: 'small',
                offset: [-10, 15],
                overflowCount: 10,
            }}
        />
    );
});
