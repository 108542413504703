import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Flex, Skeleton } from 'antd';

import Wrapper from 'shared/ui/Wrapper';
import { DeliveryInfo } from 'wigets/order/delivery-info/deliveryInfo';
import { SummaryInfo } from 'wigets/order/summary-info/summaryInfo';
import ProductsList from 'wigets/order/products-list/productsList';
import OrderHeader from 'features/Order/ui/OrderHeader';
import Unit from 'shared/ui/Unit';
import MainButton from 'shared/ui/MainButton';
import Actions from 'shared/ui/Actions';
import Back from 'shared/ui/Back';
import Placeholder from 'shared/ui/Placeholder';
import useSessionStorage from 'shared/hooks/useSessionStorage';

import { useCreateRepeatedBuyMutation } from '../../../api/checkout.api';
import { useReadOrderQuery } from '../../../api/order.api';
import { Screen } from '../../../shared/ui/Screen/ui/Screen';

export const OrderPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { data: order, isLoading } = useReadOrderQuery(+id);

    const [createRepeatedBuy, { data, isSuccess }] =
        useCreateRepeatedBuyMutation();

    const [paymentUrl, setPaymentUrl] = useSessionStorage('paymentUrl', '');
    const [subtotal, setSubtotal] = useSessionStorage('subtotal', '');
    const [deliveryPrice, setDeliveryPrice] = useSessionStorage(
        'deliveryPrice',
        '',
    );

    useEffect(() => {
        if (isSuccess && data) {
            setPaymentUrl(data?.redirect);
            setSubtotal(order?.total?.toString());
            setDeliveryPrice(order?.delivery?.delivery_sum);

            navigate('/checkout/confirm');
        }
    }, [isSuccess, data]);

    const handlePayOrder = useCallback(async () => {
        const payload = {
            telegram_user_id: order?.telegram_user_id,
            order_id: order?.order_id,
        };

        createRepeatedBuy(payload);
    }, [order]);

    const isPendingPayment = order?.status_name === 'Pending for Payment';

    return (
        <Screen>
            <Wrapper position={'first'}>
                <Flex vertical gap={24}>
                    <OrderHeader {...order} />

                    {isLoading ? (
                        <Unit>
                            <Flex vertical={false} align={'center'} gap={16}>
                                <Skeleton.Image active />
                                <Skeleton paragraph={{ rows: 1 }} active />
                            </Flex>
                        </Unit>
                    ) : (
                        <ProductsList items={order?.product_list} />
                    )}

                    {isLoading ? (
                        <Unit>
                            <Skeleton paragraph={{ rows: 1 }} active />
                        </Unit>
                    ) : (
                        <DeliveryInfo {...order?.delivery} />
                    )}

                    {isLoading ? (
                        <Unit>
                            <Skeleton active />
                        </Unit>
                    ) : (
                        <SummaryInfo {...order} />
                    )}
                </Flex>
            </Wrapper>

            {isPendingPayment && (
                <>
                    <Placeholder />
                    <Actions>
                        <MainButton onClick={handlePayOrder}>
                            Pay Now
                        </MainButton>
                    </Actions>
                </>
            )}

            <Back />
        </Screen>
    );
};
