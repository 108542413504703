import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { Flex, Form } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useInitData, useLaunchParams } from '@telegram-apps/sdk-react';
import { ReactTagManager } from 'react-gtm-ts';

import { ruFields } from 'features/Checkout/lib/ruFields';
import FormHandler from 'shared/ui/FormHandler';
import Wrapper from 'shared/ui/Wrapper';
import Actions from 'shared/ui/Actions';
import { ShopId } from 'shared/config/options';
import MainButton from 'shared/ui/MainButton';
import Placeholder from 'shared/ui/Placeholder';
import { useCart } from 'shared/hooks/useCart';
import { globalFields } from 'features/Checkout/lib/globalFields';
import Loader from 'features/Checkout/ui/Loader';
import Back from 'shared/ui/Back';

import { createCart } from '../../cart/model/create';
import useSessionStorage from '../../../shared/hooks/useSessionStorage';
import { useCreateBuyMutation } from '../../../api/checkout.api';
import { Screen } from '../../../shared/ui/Screen/ui/Screen';

export const CheckoutPage = () => {
    const navigate = useNavigate();
    const initData = useInitData(true);
    const launchParams = useLaunchParams(true);
    const [storage, setStorage] = useSessionStorage('paymentUrl', null);

    const [createBuy, { data, error, isSuccess, isLoading, isError }] =
        useCreateBuyMutation();
    const { cart } = useCart();

    const {
        updateBasketHandler,
        isSuccess: isSuccessUpdateCart,
        isLoading: isLoadingUpdateCart,
        isError: isErrorUpdateCart,
    } = createCart();

    const [form] = Form.useForm();
    const values = Form.useWatch([], form);

    const [libType, setLibType] = useState('global');
    const [isSubmittable, setIsSubmittable] = useState(false);
    const [formFields, setFormFields] = useState(globalFields);
    const [pending, setPending] = useState(false);

    useEffect(() => {
        if (initData) {
            form.setFieldValue('firstName', initData?.user?.firstName);
            form.setFieldValue('lastName', initData?.user?.lastName);

            ReactTagManager.action({
                event: 'order',
                telegram_id: initData?.user.id,
            });
        }
    }, [initData]);

    useEffect(() => {
        if (values?.destination?.value === 'ru') {
            setLibType('ru');
            setFormFields(ruFields);
        } else {
            setLibType('global');
            setFormFields(globalFields);
        }
    }, [values]);

    useEffect(() => {
        form.validateFields({ validateOnly: true })
            .then(() => setIsSubmittable(true))
            .catch(() => setIsSubmittable(false));
    }, [form, values]);

    /*    useEffect(() => {
        if (error?.status === 400 && error?.data?.data?.order_id) {
            return navigate(`checkout-error/${error?.data?.data?.order_id}`);
        }
    }, [error]);*/

    useEffect(() => {
        const productIds = cart?.map(
            (p: { product_id: number }) => p.product_id,
        );

        const deliveryPrice = values?.destination?.price;

        const payload = {
            delivery_sum: deliveryPrice?.toString(),
            telegram_user_id: +initData?.user?.id,
            first_name: values?.firstName,
            last_name: values?.lastName,
            username: initData?.user?.username,
            product_id_list: productIds || [],
            shop_id: ShopId(),
            is_mobile: false,
            full_name: `${values?.firstName} ${values?.lastName}`,
            phone: values?.phone,
            address:
                libType === 'ru'
                    ? `${values?.postal} ${values?.destination?.label} ${values?.address1 || ''} ${values?.address2 || ''}`
                    : `${values?.address1 || ''} ${values?.address2 || ''}`,
            country: values?.destination.label,
            postal_code: values?.postal.toString(),
            building: `${values?.address2 || ''}`,
            street: `${values?.street || ''}`,
            city: `${values?.city || ''}`,
        };

        if (isSuccessUpdateCart) {
            setPending(true);
            createBuy(payload).then(r => {
                r?.data?.redirect?.length > 2 && setPending(false);
            });
        }
    }, [isSuccessUpdateCart]);

    useEffect(() => {
        const productIds = cart?.map(
            (p: { product_id: number }) => p.product_id,
        );

        const deliveryPrice = values?.destination?.price;

        const payload = {
            delivery_sum: deliveryPrice?.toString(),
            telegram_user_id: +initData?.user?.id,
            first_name: values?.firstName,
            last_name: values?.lastName,
            username: initData?.user?.username,
            product_id_list: productIds || [],
            shop_id: ShopId(),
            is_mobile: false,
            full_name: `${values?.firstName} ${values?.lastName}`,
            phone: values?.phone,
            address:
                libType === 'ru'
                    ? `${values?.postal} ${values?.destination?.label} ${values?.address1 || ''} ${values?.address2 || ''}`
                    : `${values?.destination.label}, ${values?.city || ''}, ${values?.street || ''}`,

            country: values?.destination.label,
            postal_code: values?.postal.toString(),
            building: `${values?.address2 || ''}`,
            street: `${values?.street || ''}`,
            city: `${values?.city || ''}`,
        };

        let timeoutId;

        if (isError) {
            timeoutId = setTimeout(() => {
                createBuy(payload).then(r => {
                    r?.data?.redirect?.length > 2 && setPending(false);
                });
            }, 3000);
        }

        return () => {
            clearTimeout(timeoutId);
        };
    }, [isError]);

    useEffect(() => {
        if (isSuccess && data && data.redirect) {
            setStorage(data.redirect);
            navigate('/checkout/confirm');
        }
    }, [isSuccess, data]);

    useEffect(() => {
        document.body.style.overflow = 'auto';
    }, []);

    useEffect(() => {
        if (isErrorUpdateCart) {
            setPending(false);
        }
    }, [isErrorUpdateCart]);

    const handleSubmit = useCallback(async () => {
        form.validateFields({ validateOnly: false })
            .then(() => {
                setIsSubmittable(true);
            })
            .catch(() => {
                setIsSubmittable(false);
            });

        if (isSubmittable) {
            setPending(true);
            await updateBasketHandler(cart, +initData?.user?.id, ShopId());
        }
    }, [form, isSubmittable]);

    const globalLoading =
        isLoadingUpdateCart || isLoading || pending || isError;

    return (
        <Screen>
            {globalLoading ? <Loader /> : null}

            <Wrapper
                position={'first'}
                style={
                    globalLoading ? { opacity: '0.7', filter: 'blur(7px)' } : {}
                }
            >
                {formFields && (
                    <Suspense>
                        <FormHandler
                            libType={libType}
                            form={form}
                            name={'checkout'}
                            fields={formFields}
                        />
                    </Suspense>
                )}
            </Wrapper>

            <Placeholder height={launchParams?.platform === 'ios' ? 300 : 96} />

            <Actions
                style={
                    globalLoading ? { opacity: '0.7', filter: 'blur(7px)' } : {}
                }
            >
                <Flex vertical={false} align={'center'} gap={24}>
                    <MainButton disabled={globalLoading} onClick={handleSubmit}>
                        Continue
                    </MainButton>
                </Flex>
            </Actions>

            <Back />
        </Screen>
    );
};
