import React, {
    createContext,
    memo,
    PropsWithChildren,
    useEffect,
    useState,
} from 'react';

import { useReadCurrencyQuery } from '../../../../../api/product.api';

export const CurrencyContext = createContext(0);

const CurrencyProvider = memo((props: PropsWithChildren) => {
    const { children } = props;
    const pollingInterval = 3600000;

    const [currency, setCurrency] = useState(100);

    const { data } = useReadCurrencyQuery(null, {
        pollingInterval: pollingInterval,
        skipPollingIfUnfocused: false,
    });

    useEffect(() => {
        if (data) {
            setCurrency(data?.rate);
        }
    }, [data]);

    return (
        <CurrencyContext.Provider value={currency}>
            {children}
        </CurrencyContext.Provider>
    );
});

export { CurrencyProvider };
