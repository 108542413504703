import React, { memo, useEffect, useState } from 'react';
import { FormInstance, Select } from 'antd';
import styled from 'styled-components';
import { DefaultOptionType, BaseOptionType } from 'antd/es/select';

import { CheckoutFields } from 'shared/types/checkout.interface';

import { useLazyReadCountriesQuery } from '../../../../../api/catalog.api';
import useSessionStorage from '../../../../../shared/hooks/useSessionStorage';

interface CountrySelectorTypes {
    readonly form: FormInstance<CheckoutFields>;
}

type CountryOption = DefaultOptionType | BaseOptionType;

export const CountrySelector = memo((props: CountrySelectorTypes) => {
    const { form } = props;
    const [deliveryPrice, setDeliveryPrice] = useState(null);
    const [storage, setStorage] = useSessionStorage('deliveryPrice', '');

    const [getCountries, { data, isLoading }] = useLazyReadCountriesQuery();

    useEffect(() => {
        if (form) getCountries(null, true);
    }, [form]);

    useEffect(() => {
        if (deliveryPrice) {
            setStorage(deliveryPrice);
        }
    }, [deliveryPrice]);

    const handleCountrySelect = (option: CountryOption) => {
        form.setFieldValue('destination', option);
        form.setFieldValue('address1', undefined);
        form.setFieldValue('postal', undefined);
        setDeliveryPrice(option.price);
    };

    const handleSearch = (input: string, option: CountryOption) => {
        return (option?.label ?? '')
            .toLowerCase()
            .includes(input?.toLowerCase());
    };

    const sortByLabel = (a: CountryOption, b: CountryOption) => {
        return (a?.label ?? '')
            .toLowerCase()
            .localeCompare((b?.label ?? '').toLowerCase());
    };

    return (
        <StyledSelect
            style={{ height: '44px' }}
            status={form?.getFieldError('destination')?.length > 0 && 'error'}
            loading={isLoading}
            disabled={isLoading}
            showSearch
            filterOption={(input, option) => handleSearch(input, option)}
            filterSort={(optionA, optionB) => sortByLabel(optionA, optionB)}
            value={form?.getFieldValue('destination')}
            onSelect={(countryCode, option) => {
                handleCountrySelect(option);
            }}
            variant={'borderless'}
            size={'large'}
            options={data}
        />
    );
});

const StyledSelect = styled(Select)`
    border-radius: 12px;
    background: #1c1c1e;
    height: 44px;
`;
