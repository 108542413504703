import type { RouteObject } from 'react-router';
import { defer } from 'react-router-dom';

import { get } from './model/get';

const routes: RouteObject[] = [
    {
        path: 'product/:id',
        loader: async ({ params }) => {
            return defer({
                product: get(Number(params.id)),
            });
        },
        lazy: async () => {
            const page = await import('../../pages/product/ui/ProductPage');
            return {
                Component: page.ProductPage,
            };
        },
    },
];

export default routes;
