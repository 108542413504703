import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Flex } from 'antd';
import { useInitData } from '@telegram-apps/sdk-react';
import { ReactTagManager } from 'react-gtm-ts';

import Wrapper from 'shared/ui/Wrapper';
import { DeliveryInfo } from 'wigets/order/delivery-info/deliveryInfo';
import { SummaryInfo } from 'wigets/order/summary-info/summaryInfo';
import Actions from 'shared/ui/Actions';
import MainButton from 'shared/ui/MainButton';
import Placeholder from 'shared/ui/Placeholder';
import { getStatusById } from 'features/Checkout/model/getStatus';
import { StatusHeader } from 'wigets/order/status-header/statusHeader';
import ProductsList from 'wigets/order/products-list/productsList';

import useSessionStorage from '../../../shared/hooks/useSessionStorage';
import { Screen } from '../../../shared/ui/Screen/ui/Screen';

export const CheckoutStatusPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { status } = getStatusById(+id);
    const [subtotal, setSubtotal] = useSessionStorage('cart', []);
    const initData = useInitData(true);

    useEffect(() => {
        if (id) {
            setSubtotal([]);
        }
    }, [id]);

    useEffect(() => {
        if (initData) {
            ReactTagManager.action({
                event: 'success',
                telegram_id: initData?.user.id,
            });
        }
    }, [initData]);

    const handleViewHome = () => {
        navigate('/home');
    };

    return (
        <Screen>
            <Wrapper position={'first'}>
                <Flex vertical gap={24}>
                    <StatusHeader
                        orderId={+id}
                        createdAt={status?.created_at}
                    />

                    <ProductsList items={status?.product_list} />

                    <DeliveryInfo {...status?.delivery} />

                    <SummaryInfo {...status} />
                </Flex>
            </Wrapper>

            <Placeholder />

            <Actions>
                <MainButton onClick={handleViewHome}>
                    Continue Shopping
                </MainButton>
            </Actions>
        </Screen>
    );
};
