import React, { useEffect, useState } from 'react';
import { Flex, QRCode } from 'antd';
import {
    useCloudStorage,
    useHapticFeedback,
    useInitData,
    useUtils,
} from '@telegram-apps/sdk-react';
import { ReactTagManager } from 'react-gtm-ts';
import { useNavigate } from 'react-router-dom';

import P from 'shared/ui/P';
import { ParagraphSize } from 'shared/types/typography.interface';
import Unit from 'shared/ui/Unit';
import Wrapper from 'shared/ui/Wrapper';
import MainButton from 'shared/ui/MainButton';
import Page from 'shared/ui/Page';
import { WalletIcon } from 'shared/ui/Icons/Wallet';
import { copyToClipboard } from 'shared/lib/copyToClipboard';
import PayWith from 'features/Product/ui/PayWith';
import Back from 'shared/ui/Back';
import { ordersRoute } from 'shared/config/routes';

import { gap } from '../../../features/Checkout/ui/Loader/lib/options';
import useSessionStorage from '../../../shared/hooks/useSessionStorage';
import { PaymentInfo } from '../../../wigets/payment/PaymentInfo/PaymentInfo';
import { Screen } from '../../../shared/ui/Screen/ui/Screen';

export const CheckoutConfirmPage = () => {
    const btnText = 'Copy Address';

    const [text, setText] = useState<string>(btnText);

    const utils = useUtils(true);
    const initData = useInitData(true);
    const hapticFeedback = useHapticFeedback(true);
    const navigate = useNavigate();
    const cloudStorage = useCloudStorage(true);

    const [subtotal, setSubtotal] = useSessionStorage('subtotal', '');
    const [deliveryPrice, setDeliveryPrice] = useSessionStorage(
        'deliveryPrice',
        '',
    );
    const [paymentUrl, setRedirectUrl] = useSessionStorage('paymentUrl', '');

    useEffect(() => {
        document.body.style.overflow = 'auto';
    }, []);

    useEffect(() => {
        if (paymentUrl && initData) {
            ReactTagManager.action({
                event: 'order',
                telegram_id: initData?.user.id,
            });
        }
    }, [paymentUrl, initData]);

    const handleCopy = (link: string) => {
        hapticFeedback.notificationOccurred('warning');
        setText('Link copied');
        void copyToClipboard(link);
        setTimeout(() => {
            setText(btnText);
        }, 1500);
    };

    const handlePay = () => {
        ReactTagManager.action({
            event: 'pay',
            telegram_id: initData?.user.id,
        });
        cloudStorage
            .set('backRoute', '/home')
            .then(() => navigate(ordersRoute));
        hapticFeedback.notificationOccurred('success');
        utils.openTelegramLink(paymentUrl);
    };

    const linkStyle = {
        width: '330px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    };

    const infoText = [
        {
            id: 0,
            text: 'You can pay with USDT, TON, or NOT, but we accept crypto on TON network only.',
        },
        {
            id: 1,
            text: 'Sending money on the wrong network (like TRX, ETH) will result in lost funds. You can choose currency later on checkout.',
        },
    ];

    return (
        <Screen>
            <Wrapper position={'first'}>
                <Page>
                    <Unit gap={24}>
                        <Flex justify={'center'}>
                            <PayWith onlyIcon />
                        </Flex>

                        {infoText.map(e => (
                            <P
                                key={e.id}
                                style={{ textAlign: 'center' }}
                                type={'secondary'}
                                size={ParagraphSize.body}
                                text={e.text}
                            />
                        ))}
                    </Unit>

                    <div
                        style={{
                            width: '100%',
                            borderRadius: '12px',
                            border: '1px solid #FFF',
                            background: '#1C1C1E',
                            padding: gap * 4,
                        }}
                    >
                        <Flex
                            style={{ padding: '0 8px' }}
                            vertical={false}
                            align={'center'}
                            gap={16}
                        >
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='22'
                                height='23'
                                viewBox='0 0 22 23'
                                fill='none'
                            >
                                <path
                                    d='M20 20.5H2V19.5H1V15.5H2V13.5H3V11.5H4V9.5H5V7.5H6V5.5H7V3.5H8V2.5H14V3.5H15V5.5H16V7.5H17V9.5H18V11.5H19V13.5H20V15.5H21V19.5H20V20.5ZM9 6.5H8V8.5H7V10.5H6V12.5H5V14.5H4V16.5H3V18.5H19V16.5H18V14.5H17V12.5H16V10.5H15V8.5H14V6.5H13V4.5H9V6.5ZM10 7.5H12V13.5H10V7.5ZM10 14.5H12V16.5H10V14.5Z'
                                    fill='white'
                                />
                            </svg>

                            <P
                                size={ParagraphSize.h3}
                                text={
                                    'Crypto volatility can affect the price you pay in our shop.'
                                }
                            />
                        </Flex>
                    </div>

                    <Unit>
                        <Flex vertical gap={16}>
                            <PaymentInfo
                                subtotal={+subtotal}
                                delivery={+deliveryPrice}
                                isShowUSDTTotal
                            />

                            <MainButton
                                disabled={!paymentUrl}
                                onClick={handlePay}
                            >
                                <Flex gap={4} align={'center'}>
                                    Pay with Wallet
                                    <WalletIcon />
                                </Flex>
                            </MainButton>
                        </Flex>
                    </Unit>

                    <Flex justify={'center'}>
                        <P size={ParagraphSize.body} text={'or'} />
                    </Flex>

                    <Unit>
                        <Flex
                            vertical
                            align={'center'}
                            justify={'center'}
                            gap={16}
                        >
                            <Flex
                                align={'center'}
                                justify={'center'}
                                vertical={false}
                                gap={8}
                            >
                                <P
                                    type={'secondary'}
                                    size={ParagraphSize.body}
                                    text={
                                        'Scan QR-code to pay manually with Wallet'
                                    }
                                    extra={<WalletIcon />}
                                />
                            </Flex>

                            <QRCode
                                errorLevel={'L'}
                                size={200}
                                style={{
                                    borderRadius: 10,
                                    padding: 10,
                                }}
                                value={paymentUrl}
                                bordered={false}
                                color={'black'}
                                bgColor={'white'}
                            />

                            <Flex
                                vertical
                                align={'center'}
                                justify={'center'}
                                gap={8}
                            >
                                <P
                                    type={'secondary'}
                                    size={ParagraphSize.body}
                                    text={'or use link'}
                                />

                                <Flex
                                    vertical={false}
                                    gap={8}
                                    align={'center'}
                                    justify={'center'}
                                >
                                    <P
                                        style={{
                                            ...linkStyle,
                                        }}
                                        size={ParagraphSize.body}
                                        text={paymentUrl}
                                    />
                                </Flex>
                            </Flex>

                            <MainButton
                                disabled={!paymentUrl}
                                onClick={() => {
                                    handleCopy(paymentUrl);
                                }}
                            >
                                {text}
                            </MainButton>
                        </Flex>
                    </Unit>
                </Page>
            </Wrapper>

            <Back />
        </Screen>
    );
};
