import React, { memo, ReactNode, useContext } from 'react';
import { Flex, Skeleton } from 'antd';
import styled, { keyframes } from 'styled-components';

import { gap } from 'shared/config/theme/constants';
import P from 'shared/ui/P';
import { ParagraphSize } from 'shared/types/typography.interface';

import { LayoutContext } from '../../../../Utils/lib/Providers/lib/LayoutContext';

interface CategoryInHome {
    readonly image: string;
    readonly title: string;
    readonly component?: ReactNode;
    readonly onClick: () => void;

    readonly description: string;
}

export const CategoryInHome = memo((props: CategoryInHome) => {
    const { image, title, component, onClick, description } = props;
    const { wrapperWidth } = useContext(LayoutContext);

    return (
        <Flex
            vertical
            gap={gap}
            onClick={onClick}
            style={{ cursor: 'pointer' }}
        >
            {!image ? (
                <Skeleton.Image
                    style={{
                        minWidth: wrapperWidth,
                        width: wrapperWidth,
                        maxWidth: wrapperWidth,
                        minHeight: 225,
                    }}
                    active={!image}
                />
            ) : (
                <StyledImage
                    style={{
                        maxWidth: wrapperWidth,
                        minWidth: '100%',
                        width: '100%',
                        minHeight: 225,
                    }}
                    src={image}
                />
            )}

            {component}

            {!title ? (
                <Skeleton.Button
                    style={{ width: wrapperWidth / 2 }}
                    active={!title}
                    size={'small'}
                    shape={'round'}
                />
            ) : (
                <P
                    weight={400}
                    size={ParagraphSize.h3}
                    text={`"${title}" – ${description}`}
                />
            )}
        </Flex>
    );
});

const fadeIn = keyframes`
    0% { opacity: 0.2; }
    15% {opacity: 0.4;}
    35% {opacity: 0.5;}
    50% {opacity: .6;}
    75% {opacity: 0.75;}
    100% { opacity: 1; }
`;

const StyledImage = styled.div<{ src: string }>`
    animation: ${fadeIn} 1s linear;
    background-image: url(${props => props.src});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
`;
