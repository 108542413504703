import type { RouteObject } from 'react-router';

import CartPage from '../../pages/cart';

const routes: RouteObject[] = [
    {
        path: 'cart',
        element: <CartPage />,
    },
];

export default routes;
