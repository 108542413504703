import React, { memo, useEffect, useState } from 'react';
import { Flex, Skeleton } from 'antd';
import styled, { WebTarget } from 'styled-components';

import P from 'shared/ui/P';
import useSessionStorage from 'shared/hooks/useSessionStorage';
import { useTheme } from 'shared/hooks/useTheme';

import { TimeIcon } from '../lib/TimeIcon';
import { renderTimerOptions } from '../lib/options';
import { gap } from '../../../../Checkout/ui/Loader/lib/options';

interface TimeLeft {
    days: number;
    hours: number;
    minutes: number;
}

interface TimerProps {
    targetDate: number;
}

export const Timer = memo((props: TimerProps) => {
    const { targetDate } = props;
    const { colorPrimary } = useTheme();

    const [isCollectionExpired, setIsCollectionExpired] = useSessionStorage(
        'isCollectionExpired',
        0,
    );

    const [timeLeft, setTimeLeft] = useState<TimeLeft>({
        days: null,
        hours: null,
        minutes: null,
    });

    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date().getTime();
            const distance = targetDate - now;

            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor(
                (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
            );
            const minutes = Math.floor(
                (distance % (1000 * 60 * 60)) / (1000 * 60),
            );

            setTimeLeft({
                days,
                hours,
                minutes,
            });

            if (distance < 0) {
                clearInterval(interval);
                setTimeLeft({
                    days: 0,
                    hours: 0,
                    minutes: 0,
                });
                setIsCollectionExpired(1);
            } else setIsCollectionExpired(0);
        }, 1000);

        return () => clearInterval(interval);
    }, [targetDate]);

    return (
        <StyledTimer
            background={colorPrimary}
            vertical={false}
            gap={gap / 2}
            align={'center'}
        >
            <TimeIcon />

            {timeLeft?.days ? (
                <P {...renderTimerOptions()} text={`${timeLeft.days}`} />
            ) : (
                <Skeleton.Button
                    active
                    size={'small'}
                    style={{ width: 16, minWidth: 16, height: 16 }}
                />
            )}
            <P {...renderTimerOptions()} text={` days `} />
            {timeLeft?.hours === 0 ? (
                ''
            ) : timeLeft?.hours ? (
                <P {...renderTimerOptions()} text={`${timeLeft.hours}`} />
            ) : (
                <Skeleton.Button
                    active
                    size={'small'}
                    style={{ width: 16, minWidth: 16, height: 16 }}
                />
            )}
            <P {...renderTimerOptions()} text={` hours `} />
            {/*{timeLeft?.minutes === 0 ? (*/}
            {/*    ''*/}
            {/*) : timeLeft?.minutes ? (*/}
            {/*    <P {...renderTimerOptions()} text={`${timeLeft.minutes}m`} />*/}
            {/*) : (*/}
            {/*    <Skeleton.Button size={'small'} style={{ width: 10 }} />*/}
            {/*)}*/}
            <P {...renderTimerOptions()} text={`left till the drop ends`} />
        </StyledTimer>
    );
});

const StyledTimer = styled<WebTarget>(Flex)`
    width: max-content;
    padding: 4px 8px 4px 0;
    border-radius: ${props => props.gap * 2 + 'px'};
    opacity: 0.6;
`;
