import React, { createContext, memo, PropsWithChildren, useState } from 'react';
import { useViewport } from '@telegram-apps/sdk-react';

import { gap } from 'shared/config/theme/constants';
import useIsomorphicLayoutEffect from 'shared/hooks/useIsomorphicLayoutEffect';

export const LayoutContext = createContext({
    layoutWidth: 300,
    wrapperWidth: 300,
    colWidth: 300,
    height: 600,
});

interface LayoutTypes {
    layoutWidth: number;
    wrapperWidth: number;
    colWidth: number;
}
const LayoutProvider = memo((props: PropsWithChildren) => {
    const { children } = props;
    const [width, setWidth] = useState<LayoutTypes>({
        layoutWidth: 300,
        wrapperWidth: 300,
        colWidth: 300,
    });
    const [height, setHeight] = useState<number>(0);

    const requestViewport = useViewport(true);
    const quadrupleGap = gap * 4;

    const handleChangeViewport = (currentWidth: number) => {
        setWidth({
            layoutWidth: +currentWidth,
            wrapperWidth: +currentWidth - quadrupleGap,
            colWidth: (+currentWidth - quadrupleGap) / 2 + 4,
        });
    };

    useIsomorphicLayoutEffect(() => {
        if (requestViewport) {
            setWidth({
                layoutWidth: +requestViewport?.width,
                wrapperWidth: +requestViewport?.width - quadrupleGap,
                colWidth: (+requestViewport?.width - quadrupleGap) / 2 + 4,
            });

            setHeight(+requestViewport?.height);

            requestViewport.on('change:width', width =>
                handleChangeViewport(width),
            );
            requestViewport.on('change:height', height => setHeight(height));
        }
    }, [requestViewport]);

    return (
        <LayoutContext.Provider
            value={{
                layoutWidth: width.layoutWidth,
                wrapperWidth: width.wrapperWidth,
                colWidth: width.colWidth,
                height: height,
            }}
        >
            {children}
        </LayoutContext.Provider>
    );
});

export { LayoutProvider };
