import React, { CSSProperties, memo, ReactNode } from 'react';
import { Flex } from 'antd';

interface Page {
    children: ReactNode;
    style?: CSSProperties;
}

export const Page = memo((props: Page) => {
    const { children, style } = props;

    return (
        <Flex style={style} vertical gap={16}>
            {children}
        </Flex>
    );
});
