import React, { useContext } from 'react';
import Slider from 'react-slick';
import { Flex } from 'antd';
import styled from 'styled-components';

import { Products } from 'shared/types/product.interfce';
import { buildFilePath } from 'shared/lib/buildFilePath';
import { getCoverImage } from 'shared/lib/getCoverImage';
import P from 'shared/ui/P';
import { ParagraphSize } from 'shared/types/typography.interface';
import Discount from 'shared/ui/Discount';

import Price from '../../../../Product/ui/Price';
import { LayoutContext } from '../../../../Utils/lib/Providers/lib/LayoutContext';
import { DiscountContext } from '../../../../Utils/lib/Providers/lib/DiscountContext';

type ConnectedProductsType = Pick<Products, 'items'> & {
    readonly onClick: (id: number) => void;
};
export const ConnectedProducts = (props: ConnectedProductsType) => {
    const { colWidth } = useContext(LayoutContext);
    const { discount } = useContext(DiscountContext);
    const { items, onClick } = props;

    const settings = {
        dots: false,
        arrows: false,
        infinite: items?.length !== 1,
        speed: 500,
        slidesToShow: items?.length < 2 ? 1 : 2,
        slidesToScroll: 1,
        swipeToSlide: true,
        centerPadding: '10px',
    };

    return (
        <Slider {...settings}>
            {items?.map(product => (
                <Flex
                    onClick={() => onClick(+product.id)}
                    vertical
                    gap={12}
                    style={{ cursor: 'pointer' }}
                    key={product.id}
                >
                    <StyledImage
                        style={{
                            width: colWidth - 12,
                            height: colWidth * 1.18,
                            marginBottom: 12,
                        }}
                        src={buildFilePath(getCoverImage(product.images))}
                    />

                    <Flex
                        vertical
                        gap={4}
                        style={{
                            width: colWidth - 12,
                        }}
                    >
                        <P size={ParagraphSize.body} text={product.title} />

                        {discount > 0 ? (
                            <Discount.Catalog
                                discount={discount}
                                price={product.price}
                            />
                        ) : (
                            <Price
                                weight={500}
                                size={ParagraphSize.body}
                                type={'secondary'}
                                value={product.price}
                            />
                        )}
                    </Flex>
                </Flex>
            ))}
        </Slider>
    );
};

const StyledImage = styled.div<{ src: string }>`
    background-image: url(${props => props.src});
    border-radius: 10px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
`;
