import { useNavigate } from 'react-router-dom';
import React, { ReactNode } from 'react';
import { Button, Flex, ButtonProps } from 'antd';
import styled from 'styled-components';

import { gap } from 'shared/config/theme/constants';
import P from 'shared/ui/P';
import { ParagraphSize } from 'shared/types/typography.interface';

type Controls = {
    readonly title: string;
    readonly type: string;
    readonly icon?: ReactNode;
};
export const MyOrdersAndBagButtons = () => {
    const navigate = useNavigate();

    const handleView = (type: string) => {
        navigate(`/${type}`);
    };

    const buttonOptions: ButtonProps = {
        shape: 'round',
        size: 'small',
        block: true,
    };

    const controls: Controls[] = [
        { title: 'My orders', type: 'orders' },
        {
            title: 'Bag',
            type: 'cart',
            icon: (
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='20'
                    height='20'
                    viewBox='0 0 20 24'
                    fill='none'
                >
                    <path
                        d='M3.43943 24C2.3018 24 1.44415 23.7019 0.86649 23.1056C0.28883 22.5156 0 21.6304 0 20.4503V8.03995C0 6.85978 0.28883 5.97465 0.86649 5.38456C1.44415 4.78832 2.3018 4.4902 3.43943 4.4902H16.5606C17.6982 4.4902 18.5558 4.78832 19.1335 5.38456C19.7112 5.98079 20 6.86592 20 8.03995V20.4503C20 21.6243 19.723 22.5094 19.1689 23.1056C18.6148 23.7019 17.8338 24 16.8258 24H3.43943ZM3.4748 22.368H16.7993C17.2709 22.368 17.6599 22.199 17.9664 21.8609C18.2788 21.5229 18.435 21.025 18.435 20.3673V8.13216C18.435 7.46831 18.267 6.96735 17.931 6.62927C17.6009 6.2912 17.1323 6.12217 16.5252 6.12217H3.4748C2.85588 6.12217 2.38137 6.2912 2.05128 6.62927C1.72709 6.96735 1.56499 7.46831 1.56499 8.13216V20.3673C1.56499 21.025 1.72709 21.5229 2.05128 21.8609C2.38137 22.199 2.85588 22.368 3.4748 22.368ZM5.4023 4.58241C5.4023 3.97388 5.51724 3.39608 5.74713 2.84902C5.98291 2.29581 6.3071 1.80715 6.71972 1.38302C7.13823 0.952747 7.62452 0.614675 8.1786 0.368805C8.73858 0.122935 9.34571 0 10 0C10.6543 0 11.2585 0.122935 11.8126 0.368805C12.3725 0.614675 12.8588 0.952747 13.2714 1.38302C13.6899 1.80715 14.0141 2.29581 14.244 2.84902C14.4798 3.39608 14.5977 3.97388 14.5977 4.58241H13.0327C13.0327 4.04149 12.903 3.54053 12.6437 3.07952C12.3843 2.61852 12.0277 2.24971 11.5738 1.97311C11.12 1.69036 10.5953 1.54898 10 1.54898C9.40466 1.54898 8.88005 1.69036 8.42617 1.97311C7.9723 2.24971 7.61568 2.61852 7.35632 3.07952C7.09696 3.54053 6.96729 4.04149 6.96729 4.58241H5.4023Z'
                        fill='#FFFFFF'
                    />
                </svg>
            ),
        },
    ];

    return (
        <Flex gap={gap} justify={'space-between'}>
            {controls?.map(control => (
                <StyledButton
                    key={control.type}
                    {...buttonOptions}
                    onClick={() => handleView(control.type)}
                >
                    <Flex justify={'center'} align={'center'} gap={gap}>
                        <P
                            weight={510}
                            size={ParagraphSize.body}
                            text={control.title}
                        />

                        {control?.icon ? control.icon : null}
                    </Flex>
                </StyledButton>
            ))}
        </Flex>
    );
};

const StyledButton = styled(Button)`
    background: #28282a;
    border: none;
`;
