import React, { memo } from 'react';
import { Flex, Image } from 'antd';
import { useLocation } from 'react-router-dom';

import { CartUnitTypes } from 'shared/types/cart.interface';
import P from 'shared/ui/P';
import { ParagraphSize } from 'shared/types/typography.interface';
import { buildFilePath } from 'shared/lib/buildFilePath';
import Discount from 'shared/ui/Discount';

import Price from '../../../../Product/ui/Price';

export const CartUnit = memo((props: CartUnitTypes) => {
    const location = useLocation();
    const {
        title,
        price,
        options,
        quantity,
        image,
        category_name,
        hideQuantity,
        discount,
    } = props;

    const isBasket = location.pathname.indexOf('cart') === 1;

    const renderSize = options => {
        if (isBasket) {
            return options?.map(o => o.size);
        } else return options?.size;
    };

    return (
        <Flex vertical={false} gap={16}>
            <Image
                preview={false}
                style={{ borderRadius: 10, width: 80 }}
                src={buildFilePath(image)}
            />
            <Flex vertical>
                <P
                    type={'secondary'}
                    size={ParagraphSize.caption}
                    text={category_name}
                />

                <P weight={400} size={ParagraphSize.body} text={title} />

                <Flex vertical={false} gap={8}>
                    {hideQuantity ? null : (
                        <P
                            weight={400}
                            size={ParagraphSize.body}
                            text={`Qty: ${quantity}`}
                        />
                    )}

                    {options && (
                        <P
                            weight={400}
                            size={ParagraphSize.body}
                            text={`Size: ${renderSize(options)}`}
                        />
                    )}
                </Flex>

                {discount > 0 ? (
                    <Discount.CartUnit discount={discount} price={price} />
                ) : (
                    <Price
                        weight={600}
                        size={ParagraphSize.body}
                        value={price}
                    />
                )}
            </Flex>
        </Flex>
    );
});
