import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Flex } from 'antd';

interface UnitType {
    readonly onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    readonly children: ReactNode;
    readonly gap?: number;
}
export const Unit = (props: UnitType) => {
    const { children, onClick, gap } = props;

    return (
        <StyledUnit vertical gap={gap} onClick={onClick}>
            {children}
        </StyledUnit>
    );
};

const StyledUnit = styled(Flex)`
    background: #1c1c1e;
    border-radius: 10px;
    padding: 16px;
    cursor: ${props => (props.onClick ? 'pointer' : 'auto')};
`;
