import type { RouteObject } from 'react-router';

import CatalogPage from '../../pages/catalog';

const routes: RouteObject[] = [
    {
        path: 'catalog/:mainId/:connectedId',
        element: <CatalogPage />,
    },
];

export default routes;
