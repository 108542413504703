import { useUpdateCartMutation } from '../../../api/cart.api';

type Product = {
    readonly product_id: number;
    readonly quantity: number;
    readonly options: Options[];
};

type Options = {
    size: string;
};
export const createCart = () => {
    const [updateCart, { isSuccess, isLoading, isError }] =
        useUpdateCartMutation();

    const updateBasketHandler = async (
        products: Array<Product>,
        tgUserId: number,
        shopId: number,
    ) => {
        if (products?.length > 0) {
            await Promise.all(
                products.map(product => {
                    const data = {
                        telegram_user_id: +tgUserId,
                        shop_id: +shopId,
                        product_id: product?.product_id,
                        quantity: product?.quantity,
                        options: product?.options.find(o => o.size),
                    };
                    updateCart(data);
                }),
            );
        }
    };

    return { updateCart, updateBasketHandler, isSuccess, isLoading, isError };
};
