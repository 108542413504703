import React, { memo, useState } from 'react';
import { Button, Flex } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import styled, { WebTarget } from 'styled-components';

import P from 'shared/ui/P';
import { ParagraphSize } from 'shared/types/typography.interface';
import { useTheme } from 'shared/hooks/useTheme';

interface Controls {
    readonly quantity: number;
    readonly changeQuantity: (
        productId: number,
        number: number,
        size: string,
    ) => void;
    readonly productId?: number;
    readonly size?: string;
}

const MaxCartItems = 99;
const MinCartItems = 1;

export const Controls = memo((props: Controls) => {
    const { quantity, changeQuantity, productId, size } = props;

    const { colorTextDisabled, colorPrimary } = useTheme();
    const [count, setCount] = useState(quantity || 1);

    const handleChange = (type: string) => {
        if (type === 'plus') {
            setCount(count + 1);
            changeQuantity(productId, count + 1, size);
        }
        if (type === 'minus') {
            setCount(count - 1);
            changeQuantity(productId, count - 1, size);
        }
    };

    return (
        <Flex vertical={false} align={'center'} gap={12}>
            <StyledButton
                ghost
                onClick={() => handleChange('minus')}
                disabled={count <= MinCartItems}
                shape={'circle'}
            >
                <MinusOutlined
                    style={{
                        color:
                            count <= MinCartItems
                                ? colorTextDisabled
                                : colorPrimary,
                        fontSize: 14,
                    }}
                />
            </StyledButton>

            <Flex justify={'center'} style={{ minWidth: 25 }}>
                <P
                    type={'secondary'}
                    weight={590}
                    size={ParagraphSize.body}
                    text={`${quantity}`}
                />
            </Flex>

            <StyledButton
                ghost
                onClick={() => handleChange('plus')}
                disabled={count >= MaxCartItems}
                shape={'circle'}
            >
                <PlusOutlined
                    style={{
                        color:
                            count >= MaxCartItems
                                ? colorTextDisabled
                                : colorPrimary,
                        fontSize: 14,
                    }}
                />
            </StyledButton>
        </Flex>
    );
});

const StyledButton = styled<WebTarget>(Button)`
    height: 40px;
    width: 40px;
    padding-top: 16px;
    padding-bottom: 16px;
    border-color: ${props =>
        props.disabled && 'rgba(235, 235, 245, 0.30)'} !important;
`;
