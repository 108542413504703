import { FormFields } from 'shared/types/utils.interface';
export const globalFields: FormFields[] = [
    {
        label: 'City',
        name: 'city',
        hidden: true,
    },
    {
        label: 'Street',
        name: 'street',
        hidden: true,
    },

    {
        label: 'First name',
        name: 'firstName',
        input: {
            placeholder: 'Enter your first name',
            maxLength: 50,
        },
        rules: [
            {
                required: true,
                message: '',
            },
        ],
    },
    {
        label: 'Last name',
        name: 'lastName',
        input: {
            placeholder: 'Enter your last name',
            maxLength: 50,
        },
        rules: [
            {
                required: true,
                message: '',
            },
        ],
    },
    {
        label: 'Destination/Region',
        name: 'destination',
        type: 'select',
        list: null,
        input: {
            placeholder: 'Enter your destination',
        },
        rules: [
            {
                required: true,
                message: '',
            },
        ],
    },

    {
        label: 'Address Line 1',
        name: 'address1',
        input: {
            maxLength: 256,
            placeholder: 'Street address or P.O. Box',
        },
        rules: [
            {
                required: true,
                message: '',
            },
        ],
    },
    {
        label: 'Address Line 2',
        name: 'address2',
        input: {
            maxLength: 256,
            placeholder: 'Apartment, suite, unit, building, floor, etc.',
        },
        rules: [
            {
                required: true,
                message: '',
            },
            {
                max: 50,
                message: '',
            },
        ],
    },
    /*{
        label: 'City',
        name: 'city',
        input: {
            maxLength: 256,
            placeholder: 'Enter your city',
        },
        rules: [
            {
                required: true,
                message: unfilled,
            },
        ],
    },*/
    {
        label: 'Postal Code',
        name: 'postal',
        input: {
            placeholder: 'Postal Code',
            maxLength: 8,
        },
        rules: [
            {
                required: true,
                message: '',
            },
        ],
    },
    {
        label: 'Phone Number',
        name: 'phone',
        input: {
            prefix: '+',
            placeholder: 'Enter your phone number',
            maxLength: 15,
        },
        rules: [
            {
                required: true,
                message: '',
            },
            {
                pattern: new RegExp(/^\d+$/),
                message: '',
            },
        ],
    },
];
