import React from 'react';

import Document from 'features/Utils/ui/Document';
import Back from 'shared/ui/Back';
import { Screen } from 'shared/ui/Screen/ui/Screen';

export const DocumentsPage = () => {
    return (
        <Screen>
            <Document />
            <Back />
        </Screen>
    );
};
