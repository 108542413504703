import { theme } from 'antd';

import { blackColor, whiteColor } from './constants';

export const defaultThemeConfig = {
    token: {
        /*FONT*/
        fontFamily: 'Inter',
        fontSize: 15,
        fontSizeHeading1: 28,
        fontSizeHeading2: 20,
        fontSizeHeading3: 17,
        fontSizeHeading4: 13,

        titleMarginTop: 0,
        titleMarginBottom: 0,

        colorWarning: 'rgba(235, 235, 245, 0.30)',

        colorPrimary: whiteColor,
        colorLink: whiteColor,

        colorBgSpotlight: '#46464A',
        colorBorder: '#1C1C1E',

        colorSuccess: '#139C55',
        colorError: '#FF605A',
        colorInfo: 'rgba(235, 235, 245, 0.60)',
        colorTextBase: whiteColor,
        colorTextHeading: whiteColor,

        colorErrorBg: '#1C1C1E',
        colorErrorBgHover: '#1C1C1E',

        colorText: whiteColor,
        colorTextSecondary: 'rgba(235, 235, 245, 0.60)',
        secondaryColor: 'rgba(235, 235, 245, 0.60)',

        colorTextDescription: 'rgba(235, 235, 245, 0.60)',
        //colorTextDisabled: 'rgba(37, 33, 41, 0.25)',
        colorTextDisabled: '#EBEBF54D',

        //borderRadius: '3.125rem',
        wireframe: false,

        colorBgLayout: '#F8F8F8',
        colorBgContainer: '#1C1C1E',
        colorBgElevated: '#1C1C1E',

        colorBgContainerDisabled: '#2521290A',

        borderRadiusLG: 12,
        borderRadiusSM: 12,
        borderRadius: 12,
        controlItemBgHover: 'rgba(235, 235, 245, 0.30)',
        colorBorderBg: whiteColor,
    },
    components: {
        Layout: {
            bodyBg: '#0F0F0F',
        },

        Form: {
            verticalLabelPadding: '0 0 0',
            labelFontSize: 15,
            labelColor: 'rgba(235, 235, 245, 0.60)',
            itemMarginBottom: 0,
            verticalLabelMargin: '0 0 8px 0',
            labelRequiredMarkColor: 'rgba(252, 158, 151, 1)',
        },
        Typography: {
            titleMarginTop: 0,
            titleMarginBottom: 0,
        },
        Input: {
            inputFontSize: 17,
            inputFontSizeLG: 17,
            inputFontSizeSM: 17,

            paddingBlock: 8,
            paddingInline: 16,
            addonBg: '#1C1C1E',
            // activeBorderColor: '#FEB803',
            // hoverBorderColor: '#FEB803',
            activeBg: '#1C1C1E',
            hoverBg: '#1C1C1E',
        },
        Select: {
            singleItemHeightLG: 44,
            optionFontSize: 17,
            paddingBlock: 8,
            paddingInline: 16,
            optionSelectedBg: '#1C1C1E',
        },
        Card: {
            extraColor: whiteColor,
            actionsBg: '#F4F0FF',
            headerBg: '#F8F8F8',
            boxShadow:
                '0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)',
        },
        Button: {
            colorBgContainerDisabled: 'rgb(40, 40, 42)',
            // primaryColor: '#FFFFFF',
            //colorLink: '#6257d2',
            //colorLinkActive: '#6257d2',
            //colorLinkHover: '#6257d2',
            defaultBg: '#28282A',
            defaultActiveBorderColor: 'none',
            defaultHoverBg: '#28282A',
            defaultActiveBg: 'none',
            defaultBorderColor: 'none',
            borderColorDisabled: 'none',
            defaultGhostBorderColor: whiteColor,
            contentFontSize: 17,
            contentFontSizeLG: 17,
            contentFontSizeSM: 17,
            defaultColor: '#000000',
            primaryColor: '#000000',
            textHoverBg: '#000000',

            defaultHoverColor: '#000000',
            paddingBlock: 25,
            paddingBlockLG: 25,
            paddingBlockSM: 20,

            fontWeight: 510,
        },
        Radio: {
            buttonSolidCheckedActiveBg: '#FEB803',
            buttonSolidCheckedBg: '#FEB803',
            wrapperMarginInlineEnd: 0,
            buttonBg: '#F4F0FF',
        },
        Divider: {
            colorSplit: '#545458A6',
            margin: 0,
            marginLG: 0,
        },
        Dropdown: {
            paddingBlock: 8,
        },
        Badge: {
            //  color: whiteColor,
        },
    },
    algorithm: theme.defaultAlgorithm,
};

export default defaultThemeConfig;
