import React, { memo, useEffect, useState } from 'react';

interface Arrow {
    readonly position?: string;
}

const Arrow = memo((props: Arrow) => {
    const { position } = props;
    const [d, setD] = useState(null);

    useEffect(() => {
        switch (position) {
            case 'top':
                return setD(
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='30'
                        height='30'
                        viewBox='0 0 40 40'
                        fill='none'
                    >
                        <path
                            d='M19.5 14.5C19.6732 14.5 19.8345 14.5316 19.9839 14.5947C20.1272 14.6521 20.2646 14.7467 20.3961 14.8787L29.1685 23.5978C29.3895 23.8101 29.5 24.0712 29.5 24.3811C29.5 24.5876 29.4492 24.777 29.3477 24.9491C29.2461 25.1155 29.1057 25.2504 28.9265 25.3537C28.7533 25.4512 28.5562 25.5 28.3351 25.5C28.0185 25.5 27.7407 25.3881 27.5018 25.1643L19.5 17.1768L11.4892 25.1643C11.2503 25.3881 10.9725 25.5 10.6559 25.5C10.4409 25.5 10.2467 25.4512 10.0735 25.3537C9.90024 25.2504 9.76284 25.1155 9.66129 24.9491C9.55376 24.777 9.5 24.5876 9.5 24.3811C9.5 24.0712 9.61051 23.8101 9.83154 23.5978L18.6039 14.8787C18.7354 14.7467 18.8728 14.6521 19.0161 14.5947C19.1595 14.5316 19.3208 14.5 19.5 14.5Z'
                            fill='#EBEBF5'
                            fillOpacity='0.3'
                        />
                    </svg>,
                );

            case 'right':
                return setD(
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='8'
                        height='16'
                        viewBox='0 0 8 16'
                        fill='none'
                    >
                        <path
                            d='M8 8C8 8.13859 7.97705 8.26762 7.93114 8.3871C7.88941 8.50179 7.82055 8.61171 7.72457 8.71685L1.38341 15.7348C1.229 15.9116 1.03912 16 0.813772 16C0.663537 16 0.525822 15.9594 0.400626 15.8781C0.279604 15.7969 0.181534 15.6846 0.106416 15.5412C0.0354721 15.4026 0 15.2449 0 15.0681C0 14.8148 0.0813772 14.5926 0.244131 14.4014L6.05321 8L0.244131 1.5914C0.0813772 1.40024 0 1.17802 0 0.924731C0 0.752688 0.0354721 0.597372 0.106416 0.458781C0.181534 0.320191 0.279604 0.210275 0.400626 0.129032C0.525822 0.0430108 0.663537 0 0.813772 0C1.03912 0 1.229 0.088411 1.38341 0.265233L7.72457 7.28315C7.82055 7.38829 7.88941 7.49821 7.93114 7.6129C7.97705 7.7276 8 7.85663 8 8Z'
                            fill='#EBEBF5'
                            fillOpacity='0.3'
                        />
                    </svg>,
                );

            case 'bottom':
                return setD(
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='30'
                        height='30'
                        viewBox='0 0 40 40'
                        fill='none'
                    >
                        <path
                            d='M19.5 26.5C19.3268 26.5 19.1655 26.4684 19.0161 26.4053C18.8728 26.3479 18.7354 26.2533 18.6039 26.1213L9.83154 17.4022C9.61051 17.1899 9.5 16.9288 9.5 16.6189C9.5 16.4124 9.55078 16.223 9.65233 16.0509C9.75388 15.8845 9.89427 15.7496 10.0735 15.6463C10.2467 15.5488 10.4438 15.5 10.6649 15.5C10.9815 15.5 11.2593 15.6119 11.4982 15.8357L19.5 23.8232L27.5108 15.8357C27.7497 15.6119 28.0275 15.5 28.3441 15.5C28.5591 15.5 28.7533 15.5488 28.9265 15.6463C29.0998 15.7496 29.2372 15.8845 29.3387 16.0509C29.4462 16.223 29.5 16.4124 29.5 16.6189C29.5 16.9288 29.3895 17.1899 29.1685 17.4022L20.3961 26.1213C20.2646 26.2533 20.1272 26.3479 19.9839 26.4053C19.8405 26.4684 19.6792 26.5 19.5 26.5Z'
                            fill='#EBEBF5'
                            fillOpacity='0.3'
                        />
                    </svg>,
                );
        }
    }, [position]);

    return d;
});

export default Arrow;
