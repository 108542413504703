import React, { memo, Fragment } from 'react';
import { Flex, Divider } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useUtils } from '@telegram-apps/sdk-react';

import Link from 'shared/ui/Link';

import Notcoin from '../../Notcoin';
import { gap } from '../../../../Checkout/ui/Loader/lib/options';
import { footerLinks } from '../lib/options';
import { Support } from '../../../../../shared/config/options';

interface FooterTypes {
    readonly isExtra?: boolean;
}
export const Footer = memo((props: FooterTypes) => {
    const { isExtra } = props;
    const navigate = useNavigate();
    const utils = useUtils(true);

    const handleViewDocuments = (type: string) => {
        if (type === 'support') {
            const link = `https://t.me/${Support}`;
            return utils.openTelegramLink(link);
        }
        navigate(`/legal/${type}`);
    };

    const footerGapWithDividers = gap / 2;
    const footerPaddingBottom = gap * 3;

    return (
        <>
            {isExtra && <Notcoin />}
            <Flex
                gap={footerGapWithDividers}
                style={{ paddingBottom: footerPaddingBottom }}
                justify={'flex-start'}
                align={'center'}
            >
                {footerLinks?.map((link, linkIndex) => (
                    <Fragment key={link.type}>
                        {linkIndex !== 0 && (
                            <Divider type='vertical' style={{ top: '0.1em' }} />
                        )}
                        <Link
                            onClick={() => handleViewDocuments(link.type)}
                            isSimple
                            size={15}
                            text={link.title}
                            type={'secondary'}
                        />
                    </Fragment>
                ))}
            </Flex>
        </>
    );
});
