import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Layout } from 'antd';
import { useLaunchParams } from '@telegram-apps/sdk-react';

const CommonLayout = () => {
    const navigate = useNavigate();
    const launchParams = useLaunchParams(true);

    useEffect(() => {
        if (launchParams) {
            const param = launchParams?.startParam?.split('=')[0];
            const id = launchParams?.startParam?.split('=')[1];
            if (id && param) {
                return navigate(`/${param}/${id}`);
            }
        }
    }, [launchParams]);

    return (
        <StyledLayout>
            <Outlet />
        </StyledLayout>
    );
};

export default CommonLayout;

const StyledLayout = styled(Layout)`
    min-height: 100vh;
    overflow-x: hidden;
`;
