import React, { memo, useContext, useLayoutEffect, useState } from 'react';

import { PriceType } from 'shared/types/utils.interface';
import P from 'shared/ui/P';

import { CurrencyContext } from '../../../../Utils/lib/Providers/lib/CurrencyContext';

export const Price = memo((props: PriceType) => {
    const currency = useContext(CurrencyContext);
    const { value, type, size, weight, isSimple, style } = props;
    const [price, setPrice] = useState<string | number>();
    useLayoutEffect(() => {
        if (value) {
            setPrice(+value);
            // return setPrice(Number.parseFloat(String(value)).toFixed(2));
        }
    }, [value]);

    const renderPrice = (currentValue: number, currentCurrency: number) => {
        const calcPrice = currentValue * currentCurrency;
        return Math.ceil(calcPrice).toLocaleString('en-US');
    };

    return (
        <P
            style={style}
            type={type}
            size={size}
            weight={weight || 700}
            // text={+price / currency + ' NOT'}
            text={renderPrice(+price, +currency) + ' NOT'}
        />
    );
});
