import type { RouteObject } from 'react-router';

import DocumentsPage from '../../pages/documents';

const routes: RouteObject[] = [
    {
        path: 'legal/:type',
        element: <DocumentsPage />,
    },
];

export default routes;
