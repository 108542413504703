import env from '@beam-australia/react-env';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { Currency, Product, Products } from '../shared/types/product.interfce';

export const productApi = createApi({
    reducerPath: 'productApi',
    baseQuery: fetchBaseQuery({
        baseUrl: env('GOAPI_URL'),
    }),
    tagTypes: ['Product'],
    endpoints: builder => ({
        readProducts: builder.query<Products, number>({
            query: category_id => {
                return {
                    url: `/categories/${category_id}/products`,
                };
            },
            transformResponse: (res: { data: Product[] }, meta) => {
                return {
                    items: res.data || [],
                    total:
                        Number(meta.response.headers.get('Items-Count')) || 0,
                };
            },
            providesTags: ['Product'],
        }),
        readProduct: builder.query<Product, number>({
            keepUnusedDataFor: 600,
            query: id => ({
                method: 'GET',
                url: `/products/${id}`,
            }),
            transformResponse: (response: { data: Product }) => {
                return response.data;
            },
            providesTags: ['Product'],
        }),
        readCurrency: builder.query<Currency, void>({
            keepUnusedDataFor: 3600,
            query: () => ({
                method: 'GET',
                url: `/exchangerate`,
            }),
            transformResponse: (response: { data: Currency }) => {
                return response.data;
            },
            providesTags: ['Product'],
        }),
    }),
});

export const { useLazyReadProductsQuery, useReadCurrencyQuery } = productApi;
