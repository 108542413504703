import React, { useEffect, useState } from 'react';
import { Flex, Spin } from 'antd';
import styled from 'styled-components';

import P from 'shared/ui/P';
import { ParagraphSize } from 'shared/types/typography.interface';

import { centered, gap, infoText } from '../lib/options';
import { Screen } from '../../../../../shared/ui/Screen/ui/Screen';

const statusList = [
    'Counting coins, not vibes... Hold tight!',
    'Stacking up your digital dough. Almost there!',
    'Making your wallet as lit as your feed. Just a sec!',
    'Money moves loading... Stay woke!',
    'Turning zeros into hero coins. Hang on!',
    "Ready to flex those gains? We're almost done!",
    "Your crypto is cooking! Don't touch that dial.",
    'Brb, just securing your financial glow-up.',
    'No cap, this is about to be fire. Loading...',
    'Getting your digital stack ready to slay the day!',
];

export const Loader = () => {
    const [statusText, setStatusText] = useState(statusList[0]);
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex(prevIndex => {
                const newIndex = (prevIndex + 1) % statusList.length;
                setStatusText(statusList[newIndex]);
                return newIndex;
            });
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    return (
        <Screen>
            <StyledWrapper {...centered}>
                <Flex
                    vertical
                    justify={'flex-start'}
                    align={'center'}
                    style={{
                        height: '100%',
                        marginTop: gap * 8,
                        width: '100%',
                    }}
                    gap={gap * 2}
                >
                    <div
                        style={{
                            width: '90%',
                            borderRadius: '12px',
                            border: '1px solid #FFF',
                            background: '#1C1C1E',
                            padding: gap * 4,
                            margin: '0 1rem',
                        }}
                    >
                        <Flex
                            vertical
                            align={'center'}
                            justify={'center'}
                            gap={16}
                        >
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='22'
                                height='23'
                                viewBox='0 0 22 23'
                                fill='none'
                            >
                                <path
                                    d='M20 20.5H2V19.5H1V15.5H2V13.5H3V11.5H4V9.5H5V7.5H6V5.5H7V3.5H8V2.5H14V3.5H15V5.5H16V7.5H17V9.5H18V11.5H19V13.5H20V15.5H21V19.5H20V20.5ZM9 6.5H8V8.5H7V10.5H6V12.5H5V14.5H4V16.5H3V18.5H19V16.5H18V14.5H17V12.5H16V10.5H15V8.5H14V6.5H13V4.5H9V6.5ZM10 7.5H12V13.5H10V7.5ZM10 14.5H12V16.5H10V14.5Z'
                                    fill='white'
                                />
                            </svg>

                            <Flex vertical>
                                <P
                                    style={{ textAlign: 'center' }}
                                    size={ParagraphSize.h3}
                                    text={"Don't refresh this page."}
                                />
                                <P
                                    style={{ textAlign: 'center' }}
                                    size={ParagraphSize.h3}
                                    text={'You will lose data.'}
                                />
                            </Flex>
                        </Flex>
                    </div>

                    <Flex vertical gap={gap * 2} style={{ marginTop: gap * 8 }}>
                        <Spin size={'large'} />
                        <Flex
                            vertical
                            gap={gap}
                            style={{ padding: `0 ${gap}px` }}
                            {...centered}
                        >
                            {infoText.map(t => (
                                <P
                                    style={{ textAlign: 'center' }}
                                    key={t.key}
                                    weight={510}
                                    type={'secondary'}
                                    size={ParagraphSize.body}
                                    text={t.text}
                                />
                            ))}
                            <P
                                weight={510}
                                type={'secondary'}
                                size={ParagraphSize.body}
                                text={statusText}
                            />
                        </Flex>
                    </Flex>
                </Flex>
            </StyledWrapper>
        </Screen>
    );
};

const StyledWrapper = styled(Flex)`
    overflow: hidden;
    position: absolute;

    width: 100%;
    height: 100vh;
    z-index: 100;
    background: rgba(0, 0, 0, 0.5);
`;
