import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Terms } from '../../../lib/Terms';
import { Policy } from '../../../lib/Privacy';

export const Document = () => {
    const { type } = useParams();
    const [node, setNode] = useState(null);

    useEffect(() => {
        if (type) {
            switch (type) {
                case 'terms':
                    return setNode(Terms);
                case 'privacy':
                    return setNode(Policy);
            }
        }
    }, [type]);

    return <div style={{ padding: '1rem' }}>{node}</div>;
};
