import { Images } from '../types/product.interfce';

export const getCoverImage = (images: Images[]): string => {
    //return images.map(({ file }) => file)?.[0];

    if (images && Array.isArray(images) && images.length > 0) {
        const { file, original } = images[0];
        return file ? file : original;
    }
};
