import { ParagraphSize } from 'shared/types/typography.interface';
import { setExpiredDate } from 'shared/config/options';

export const targetDate = () => {
    return new Date(setExpiredDate()).getTime();
};

export const renderTimerOptions = () => {
    return {
        weight: 590,
        style: { color: '#8D8D93' },
        size: ParagraphSize.caption,
    };
};
