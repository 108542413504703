import React, { memo } from 'react';

import P from '../../../shared/ui/P';
import { ParagraphSize } from '../../../shared/types/typography.interface';

interface Title {
    readonly text: string;
}
export const Title = memo((props: Title) => {
    const { text } = props;

    return <P size={ParagraphSize.h1} text={text} />;
});
