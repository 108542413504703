import { createRoot, hydrateRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import App from './App';
import { routes } from './routes';

const container = document.getElementById('root');
const router = createBrowserRouter(routes, {
    basename: process.env.PUBLIC_PATH,
});

if (container.hasChildNodes()) {
    hydrateRoot(
        container,
        <App>
            <RouterProvider router={router} />
        </App>,
    );
} else {
    createRoot(container).render(
        <App>
            <RouterProvider router={router} />
        </App>,
    );
}
