import React, { ReactNode, useEffect } from 'react';

export const Screen = ({ children }: { children: ReactNode }) => {
    useEffect(() => {
        if (typeof window !== 'undefined' && window.scrollY !== 0) {
            window.scroll(0, 0);
        }
    }, []);

    return <>{children}</>;
};
