import React, { memo } from 'react';
import { Flex } from 'antd';

import P from 'shared/ui/P';
import { ParagraphSize } from 'shared/types/typography.interface';

interface OrderHeader {
    readonly order_id: number;
}
export const OrderHeader = memo((props: OrderHeader) => {
    const { order_id } = props;

    return (
        <Flex justify={'center'} align={'center'} vertical>
            <P size={ParagraphSize.h1} text={'Order Details'} />
            <P
                type={'secondary'}
                size={ParagraphSize.h3}
                text={`Order number: ${order_id}`}
            />
        </Flex>
    );
});
