import type { RouteObject } from 'react-router';

import { OrderPage } from '../../pages/order';

const routes: RouteObject[] = [
    {
        path: 'orders',
        lazy: async () => {
            const page = await import('../../pages/order/ui/OrdersPage');
            return {
                Component: page.OrdersPage,
            };
        },
    },
    {
        path: 'orders/:id',
        element: <OrderPage />,
    },
];

export default routes;
