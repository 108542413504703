import React from 'react';
import { Flex } from 'antd';
import { useUtils } from '@telegram-apps/sdk-react';

import P from 'shared/ui/P';
import { ParagraphSize } from 'shared/types/typography.interface';
import Link from 'shared/ui/Link';
import { Support } from 'shared/config/options';

export const Header = () => {
    const utils = useUtils(true);

    const handleContactSeller = async () => {
        const link = `https://t.me/${Support}`;
        utils.openTelegramLink(link);
    };

    return (
        <Flex justify={'space-between'}>
            <P weight={700} size={ParagraphSize.h2} text={'Orders'} />
            <Link onClick={handleContactSeller} text={'Contact Support'} />
        </Flex>
    );
};
