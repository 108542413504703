import React from 'react';
import { Flex } from 'antd';

import { text } from './lib/text';
import P from '../../../../shared/ui/P';
import { ParagraphSize } from '../../../../shared/types/typography.interface';
import Unit from '../../../../shared/ui/Unit';
import { DocumentList } from '../../ui/Document/ui/DocumentList';
import { DocumentSubParagraph } from '../../ui/Document/ui/DocumentSubParagraph';

export const Policy = () => {
    return (
        <Flex vertical gap={12}>
            <P size={ParagraphSize.h2} text={'Privacy Policy'} />

            {text.map((p, i) => (
                <Unit key={i}>
                    <Flex vertical gap={16}>
                        <Flex vertical gap={4}>
                            <P
                                weight={590}
                                size={ParagraphSize.h3}
                                text={`${p.number} ` + `${p.title}`}
                            />
                            <P
                                size={ParagraphSize.secondaryBody}
                                text={p.text}
                            />
                        </Flex>
                        {p.subParagraphs && (
                            <DocumentSubParagraph
                                subParagraphs={p.subParagraphs}
                            />
                        )}
                        {p?.list && (
                            <DocumentList
                                list={p.list}
                                listTitle={p.listTitle}
                            />
                        )}
                    </Flex>
                </Unit>
            ))}
        </Flex>
    );
};
