import env from '@beam-australia/react-env';

class HttpClient {
    private GOAPI_URL: string = env('GOAPI_URL');
    private API_URL: string = env('API_URL');

    async get(url: string, isGo: boolean) {
        try {
            const response = await fetch(
                `${isGo ? this.GOAPI_URL : this.API_URL}${url}`,
                {},
            );
            return await response.json();
        } catch (e) {
            console.error(`HTTP Client err: ${e}`);
            throw e;
        }
    }
}

const httpClient = new HttpClient();

export default httpClient;
