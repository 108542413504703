import React from 'react';

import { SlickStyle } from 'shared/config/theme/slick';
import { GlobalStyle } from 'shared/config/theme/global';
import PlacesStyles from 'shared/config/theme/placesStyles';
import { AntdFixesStyle } from 'shared/config/theme/antdFixes';

export const Styles = () => {
    const styles = [SlickStyle, GlobalStyle, PlacesStyles, AntdFixesStyle];

    return (
        <>
            {styles.map((Component, index) => (
                <Component key={index} />
            ))}
        </>
    );
};
