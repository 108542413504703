import React from 'react';
import { Flex } from 'antd';

import P from 'shared/ui/P';
import { ParagraphSize } from 'shared/types/typography.interface';
import { DocumentSubParagraphType } from 'shared/types/utils.interface';

import { DocumentList } from './DocumentList';

export const DocumentSubParagraph = ({
    subParagraphs,
}: DocumentSubParagraphType) => {
    return (
        <Flex vertical gap={16}>
            {subParagraphs.map(paragraph => (
                <Flex vertical gap={8} key={paragraph.title}>
                    <P
                        weight={590}
                        size={ParagraphSize.body}
                        style={{ lineHeight: '22px' }}
                        text={`${paragraph.number} ` + `${paragraph.title}`}
                    />
                    <P
                        size={ParagraphSize.secondaryBody}
                        text={paragraph.text}
                    />
                    {paragraph?.list && (
                        <DocumentList
                            list={paragraph.list}
                            listTitle={paragraph.listTitle}
                        />
                    )}
                </Flex>
            ))}
        </Flex>
    );
};
