import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { catalogApi } from '../../api/catalog.api';
import rootReducer from './rootReducer';
import { productApi } from '../../api/product.api';
import { cartApi } from '../../api/cart.api';
import { orderApi } from '../../api/order.api';
import { checkoutApi } from '../../api/checkout.api';

export const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat([
            catalogApi.middleware,
            productApi.middleware,
            cartApi.middleware,
            orderApi.middleware,
            checkoutApi.middleware,
        ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
