import { useEffect } from 'react';

import { useLazyReadStatusQuery } from '../../../api/checkout.api';

export const getStatusById = (id: number) => {
    const [getStatus, { data: status }] = useLazyReadStatusQuery();

    useEffect(() => {
        if (id) {
            getStatus(+id);
        }
    }, [id]);

    return { status };
};
